import { AppContext } from 'shared/AppContext';
import { useContext } from 'react';

export function useCommodityTarget() {
  const { targets, selectedTarget, setSelectedTarget } = useContext(AppContext);

  const target = targets?.find(t => t.id === selectedTarget);

  return { target, targets, selectedTarget, setSelectedTarget };
}
