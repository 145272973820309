import { faFileDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/Button/Button';

type DownloadButtonProps = {
  onClick: () => void;
  isLoading: boolean;
  text: string;
};

const DownloadButton = ({ onClick, isLoading, text }: DownloadButtonProps) => (
  <Button onClick={onClick}>
    {isLoading ? (
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
    ) : (
      <FontAwesomeIcon icon={faFileDownload} />
    )}
    <span style={{ marginLeft: '8px' }}>{text}</span>
  </Button>
);

export default DownloadButton;
