import hero from 'shared/assets/images/commodity_hero.png';

import styles from './Landing.module.scss';

import { useTranslation } from 'react-i18next';
function Landing() {
  const { t } = useTranslation();
  document.title = t('Forecasting.title');
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img className={styles.heroImg} alt="Commodity-A" src={hero} />
        <div>
          <h1>What is Commodity-AI?</h1>
          <br />
          <p>
            Commodity-AI is an advanced analytics solution that utilizes deep
            learning and causal inference to make commodity price predictions,
            simulate scenarios, and improve decision-making. It greatly enhances
            the processes of buying, selling, or hedging commodities. This asset
            was created after several successful projects, where our
            interdisciplinary teams (QuantumBlack + Acre + Integrative)
            implemented advanced analytics to assist clients in hedging,
            procurement, and selling of commodities.
          </p>
          <br />
          <p className={styles.disclaimer}>
            Nothing in this tool constitutes or shall be construed as advice or
            recommendation to proceed or not proceed with the purchase or sale
            of any goods, commodities interests, including without limitation
            futures, swaps, options, and cash settled forwards.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Landing;
