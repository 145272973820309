import styles from './CustomLegend.module.scss';

type CustomLegendProps = {
  legends: {
    text: string;
    color: string;
  }[];
};

const CustomLegend = ({ legends }: CustomLegendProps) => (
  <div className={styles.wrapper}>
    {legends.map((legend, idx) => (
      <div key={idx}>
        <span style={{ backgroundColor: legend.color }}>&nbsp;</span>
        <p>{legend.text}</p>
      </div>
    ))}
  </div>
);

export default CustomLegend;
