import { useContext, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';

import { AppContext } from 'shared/AppContext';
import { mapDataFrametoTableProps, axisfont } from 'shared/utils/plotly';
import { useCommodityTarget } from 'shared/hooks/useCommodityTarget';
import Notifier from 'shared/services/Notifier/Notifier';

import Subheader from 'components/Subheader/Subheader';
import Dropdown from 'components/Dropdown/Dropdown';
import Table from 'components/Table/Table';
import BacktestingVideo from 'components/BacktestingVideo/BacktestingVideo';
import PageSubtitle from 'components/PageSubtitle/PageSubtitle';
import Skeleton from 'components/Skeleton/Skeleton';
import DownloadButton from 'components/DownloadButton/DownloadButton';
import ContainerBlock from 'components/ContainerBlock/ContainerBlock';

import styles from './Backtesting.module.scss';

import { downloadBacktesting } from 'service/download.service';
import { getBacktestingMetrics } from 'service/backtesting.service';

function MetricsSeriesPlot({ series }: { series: any }) {
  const boxplot: any = [
    {
      x: series.value,
      name: series.metric,
      marker: {
        symbol: '',
      },
      boxpoints: false,
      showlegend: true,
      type: 'box',
    },
  ];
  const histogram: any = [
    {
      x: series.value,
      name: series.metric,
      type: 'histogram',
      nbinsx: 20,
    },
  ];
  const width = (window.innerWidth - 395) / 2;

  return (
    <>
      <Plot
        config={{ displayModeBar: false, displaylogo: false }}
        data={boxplot}
        layout={{
          height: 175,
          width: width,
          paper_bgcolor: 'rgba(0, 0, 0, 0)',
          plot_bgcolor: 'rgba(24, 24, 24, 0.6)',
          font: {
            color: 'white',
          },
          margin: {
            t: 0,
            b: 0,
            l: 16,
            r: 0,
          },
          xaxis: {
            gridcolor: 'rgb(49,51,63)',
          },
          yaxis: {
            visible: false,
          },
          legend: {
            xanchor: 'auto',
            yanchor: 'top',
            x: 0,
            y: 1,
          },
        }}
      />
      <Plot
        data={histogram}
        layout={{
          paper_bgcolor: 'rgba(0, 0, 0, 0)',
          plot_bgcolor: 'rgba(24, 24, 24, 0.6)',
          height: 300,
          width: width,
          margin: {
            t: 0,
            b: 16,
            l: 16,
            r: 0,
          },
          xaxis: {
            title: {
              font: axisfont,
            },
          },
          yaxis: {
            gridcolor: 'rgb(49,51,63)',
            title: {
              font: axisfont,
            },
          },
          font: {
            color: 'white',
          },
        }}
        config={{
          displayModeBar: false,
        }}
      />
    </>
  );
}

type LoadingState = {
  video: boolean;
  metrics: boolean;
  pricePosition: boolean;
  priceDirection: boolean;
};

const initialLoadingState: LoadingState = {
  video: true,
  metrics: true,
  pricePosition: true,
  priceDirection: true,
};

const Backtesting = () => {
  const { t } = useTranslation();
  const { target, targets, selectedTarget, setSelectedTarget } =
    useCommodityTarget();
  const { serverSentEventId } = useContext(AppContext);

  const [loadingState, setLoadingState] =
    useState<LoadingState>(initialLoadingState);
  const [metrics, setMetrics] = useState<any>();
  const [isDownloadingBacktesting, setIsDownloadingBacktesting] =
    useState(false);

  const namespace = target?.price_prediction;
  let summaryTableProps, backtestTableProps;

  if (metrics) {
    const { backtest_results_curves, summary } = metrics;
    summaryTableProps = mapDataFrametoTableProps(summary);
    backtestTableProps = mapDataFrametoTableProps(backtest_results_curves);
  }

  useEffect(() => {
    document.title = t('Backtesting.title');
    if (!target?.price_prediction) return;

    const fetchMetrics = async () => {
      setLoadingState(initialLoadingState);
      setMetrics(undefined);

      try {
        const bckTestingData = await getBacktestingMetrics(
          target.price_prediction,
        );
        setMetrics(bckTestingData);
      } catch (error) {
        console.error(error);
        // Notifier.show('Error trying to get metrics.', { type: 'error' });
      } finally {
        setLoadingState(state => ({ ...state, metrics: false }));
      }
    };
    fetchMetrics();
  }, [serverSentEventId, t, target]);

  const handleDownloadBacktesting = async () => {
    setIsDownloadingBacktesting(true);

    try {
      const featureImportanceFile = await downloadBacktesting(namespace!);
      FileSaver.saveAs(featureImportanceFile);
    } catch (error) {
      // alert('Error trying to download file');
      console.error('Error trying to download file', error);
      // Notifier.show('Error trying to download file', { type: 'error' });
    } finally {
      setIsDownloadingBacktesting(false);
    }
  };

  const SMAPE =
    metrics?.summary_dict?.median[`smape_${metrics.horizon_days}d`] ?? '??';
  const MDA =
    metrics?.summary_dict?.median[`mda_first_point_${metrics.horizon_days}d`] ??
    '??';

  return (
    <div>
      <Subheader title={t('Performance.performanceSummary')}>
        <div className={styles.dropdownContainer}>
          <Dropdown
            options={targets}
            select={setSelectedTarget}
            selected={selectedTarget}
          />
        </div>
      </Subheader>
      {loadingState.metrics ? (
        <Skeleton height={72} />
      ) : (
        <PageSubtitle>
          The price prediction model has been backtested on 3 years of
          historical and has attained a median <b>SMAPE of {SMAPE}%</b> and a
          median <b>MDA of {MDA}%</b>
        </PageSubtitle>
      )}

      <div className={styles.downloadBtnWrapper}>
        <DownloadButton
          onClick={handleDownloadBacktesting}
          isLoading={isDownloadingBacktesting}
          text="Download Backtest results"
        />
      </div>

      {!namespace ? (
        <Skeleton height={250} />
      ) : (
        <BacktestingVideo
          target={namespace}
          setIsVideoLoading={() =>
            setLoadingState(state => ({
              ...state,
              video: !state.video,
            }))
          }
        />
      )}

      <div className={styles.infoBlocks}>
        {loadingState.metrics ? (
          <>
            <>
              <Skeleton height={300} />
              <Skeleton height={300} />
              <Skeleton height={300} />
              <Skeleton height={300} />
            </>
          </>
        ) : (
          <>
            <ContainerBlock>
              <div className={styles.containerBlockHeader}>
                <h3>Error Distribution snape in 6 months</h3>
              </div>
              <MetricsSeriesPlot series={metrics.metrics_series[0]} />
            </ContainerBlock>
            <ContainerBlock>
              <div className={styles.containerBlockHeader}>
                <h3>Error Distributionmean directional accuracy in 6 months</h3>
              </div>
              <MetricsSeriesPlot series={metrics.metrics_series[1]} />
            </ContainerBlock>
            {summaryTableProps && (
              <ContainerBlock>
                <div className={styles.containerBlockHeader}>
                  <h3>Summary of backtest results</h3>
                </div>
                <Table {...summaryTableProps} />
              </ContainerBlock>
            )}
            {backtestTableProps && (
              <ContainerBlock>
                <div className={styles.containerBlockHeader}>
                  <h3>Backtest results for all curves</h3>
                </div>
                <Table
                  {...backtestTableProps}
                  pagination={true}
                  manualPageSize={13}
                />
              </ContainerBlock>
            )}
          </>
        )}
      </div>

      {/* <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Aluminium Lme Price Position - Confusion Matrix</h3>
        </div>
        {loadingState.pricePosition || !pricePosition
          ? <Skeleton height={450} />
          : (
            <ConfusionMatrix confusion_matrix={pricePosition.confusion_matrix} />
          )}
      </ContainerBlock> */}
    </div>
  );
};

export default Backtesting;
