import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IconAction.module.scss';

type IconActionProps = {
  text: string;
  linkTo: string;
  icon: any;
};

const IconAction = ({ text, linkTo, icon }: IconActionProps) => (
  <NavLink to={linkTo} className={styles.wrapper}>
    {text}
    <FontAwesomeIcon className={styles.icon} icon={icon} />
  </NavLink>
);

export default IconAction;
