import React, { useState, useRef, useEffect } from 'react';

interface SliderProps {
  min: number;
  max: number;
  value: number;
  trackWidth?: number;
  disable?: boolean;
  onChange: (value: number) => void;
}

const InputSlider: React.FC<SliderProps> = ({
  min,
  max,
  value,
  trackWidth = 400,
  disable,
  onChange,
}) => {
  const [position, setPosition] = useState<number>(0);
  const positionRef = useRef<number>(position);

  const trackRef = useRef<HTMLDivElement>(null);

  const thumbSize = 12;
  const trackHeight = 5;
  const thumbOffset = thumbSize / 2;
  const maxPosition = 1 - thumbSize / trackWidth;

  useEffect(() => {
    const position = ((value - min) / (max - min)) * maxPosition;
    setPosition(position);
  }, [value]);

  useEffect(() => {
    positionRef.current = position;
  }, [position]);

  const handleClickOrMouseMove = (
    event: MouseEvent | React.MouseEvent<HTMLDivElement>,
  ) => {
    if (disable) return;
    if (trackRef.current) {
      const rect = trackRef.current.getBoundingClientRect();
      const trackWidth = trackRef.current.clientWidth;
      const offsetX = event.clientX - rect.left;
      let newPosition = Math.max(
        0,
        Math.min(1, (offsetX - thumbOffset) / trackWidth),
      );

      // Adjust newPosition to account for the thumb size
      newPosition = Math.min(newPosition, maxPosition);

      setPosition(newPosition);

      const value = min + (newPosition / maxPosition) * (max - min);
      onChange(value);
    }
  };

  const handleMouseDown = () => {
    if (disable) return;
    document.addEventListener('mousemove', handleClickOrMouseMove);
    document.addEventListener('mouseup', function handleMouseUp() {
      const pos = positionRef.current;
      const value = min + (pos / maxPosition) * (max - min);

      onChange(value);

      document.removeEventListener('mousemove', handleClickOrMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    });
  };

  const cursor = disable ? 'not-allowed' : 'pointer';
  const color = disable ? 'rgb(178,185,190)' : 'rgb(34, 81, 255)';

  const styles: Record<string, any> = {
    tracker: {
      width: trackWidth,
      height: trackHeight,
      borderRadius: trackHeight / 2,
      backgroundColor: '#e0e0e0',
      position: 'relative',
      cursor: cursor,
    },
    minLabel: {
      position: 'absolute',
      top: '100%',
      left: '24px',
      transform: 'translate(-50%, 5px)',
      fontSize: '14px',
      userSelect: 'none',
    },
    maxLabel: {
      position: 'absolute',
      top: '100%',
      right: '24px',
      transform: 'translate(50%, 5px)',
      fontSize: '14px',
      userSelect: 'none',
    },
    label: {
      position: 'absolute',
      top: '-24px',
      left: `calc(${position * 100}% + ${thumbOffset}px)`,
      maxWidh: '80px',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 'bold',
      color: color,
      userSelect: 'none',
    },
    fill: {
      content: "''",
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      backgroundColor: color,
      width: `${position * trackWidth + 1}px`,
      borderRadius: '5px 0 0 5px',
    },
    thumb: {
      position: 'absolute',
      left: `calc(${position * 100}%)`,
      top: '50%',
      transform: 'translateY(-50%)',
      width: `${thumbSize}px`,
      height: `${thumbSize}px`,
      backgroundColor: color,
      border: `1px solid ${color}`,
      borderRadius: '50%',
      cursor: cursor,
    },
  };

  return (
    <div
      ref={trackRef}
      style={styles.tracker}
      onMouseDown={handleMouseDown}
      onClick={handleClickOrMouseMove}
    >
      <div style={styles.minLabel}>{min.toFixed(2)}</div>
      <div style={styles.maxLabel}>{max.toFixed(2)}</div>
      <div style={styles.label}>{value.toFixed(2)}</div>
      <div style={styles.thumb} />
      <div style={styles.fill} />
    </div>
  );
};

export default InputSlider;
