import classNames from 'classnames';
import React from 'react';
import PopupMenu, { PopupMenuProps } from '../PopupMenu/PopupMenu';
import IconButton from './IconButton';
import styles from './IconButton.module.scss';

interface PopupIconButtonProps extends Omit<PopupMenuProps, 'button'> {
  icon: React.ReactNode;
  label?: React.ReactNode | string;
  action?: () => void;
}

export const PopupIconButton = (props: PopupIconButtonProps) => {
  if (props.action) {
    return (
      <div
        className={classNames(styles.container, styles.defaultActionContainer)}
      >
        <div onClick={props.action} className={styles.icon}>
          {props.icon}
        </div>

        <div className={styles.labelWrapper}>
          <PopupMenu
            {...props}
            highlightClassName={styles.secondaryHighlight}
            button={<div className={styles.label}>{props.label}</div>}
          >
            {props.children}
          </PopupMenu>
        </div>
      </div>
    );
  }

  return (
    <PopupMenu
      {...props}
      button={<IconButton icon={props.icon} label={props.label} />}
      highlightClassName={styles.primaryHighlight}
    >
      {props.children}
    </PopupMenu>
  );
};

export const preventPopupClosingOnClick = (e: any) => e.stopPropagation();

export default PopupIconButton;
