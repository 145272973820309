import Plot from 'react-plotly.js';

import Table from 'components/Table/Table';

import { mapDataFrametoTableProps } from 'shared/utils/plotly';

export interface BacktestingResultsProps {
  backtest_series: Record<string, any>[];
  price_description: string;
  price_units: string;
  df_backtest_metrics: Record<string, any>;
}

export function BacktestingResults(
  backtestingResults: BacktestingResultsProps,
) {
  const { backtest_series } = backtestingResults;

  const metricsTableProps = mapDataFrametoTableProps(
    backtestingResults.df_backtest_metrics,
    true,
  );

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <Plot
          config={{ displayModeBar: false, displaylogo: false }}
          useResizeHandler={true}
          style={{ width: '100%', height: '450px' }}
          data={backtest_series.map((item: any) => ({
            x: item.date,
            name: item.name,
            y: item.value,
            mode: 'lines',
          }))}
          layout={{
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            legend: {
              orientation: 'h',
            },
            margin: {
              t: 0,
            },
            font: {
              color: 'white',
            },
            yaxis: {
              title: '',
              linecolor: 'rgba(255,255,255,.5)',
              gridcolor: 'rgba(255,255,255,.5)',
            },
            xaxis: {
              title: '',
              linecolor: 'rgba(255,255,255,.5)',
              gridcolor: 'rgba(255,255,255,.5)',
            },
          }}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <Table {...metricsTableProps} />
      </div>
    </div>
  );
}
