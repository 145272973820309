import classNames from 'classnames';
import styles from './SigmaControls.module.scss';

type SigmaControlsProps = {
  onChangeSigma: (sigma: number) => void;
  currentSigma: number;
};

const SigmaControls = ({ currentSigma, onChangeSigma }: SigmaControlsProps) => (
  <div className={styles.wrapper}>
    <p className={styles.label}>Confidence (α)</p>
    <button
      className={classNames(styles.btn, currentSigma === 1 && styles.active)}
      onClick={() => onChangeSigma(1)}
    >
      68.27%
    </button>
    <button
      className={classNames(styles.btn, currentSigma === 2 && styles.active)}
      onClick={() => onChangeSigma(2)}
    >
      95.45%
    </button>
    <button
      className={classNames(styles.btn, currentSigma === 3 && styles.active)}
      onClick={() => onChangeSigma(3)}
    >
      99.73%
    </button>
  </div>
);

export default SigmaControls;
