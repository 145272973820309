import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from '../../application.json';

import en from './locales/en.json';
import ru from './locales/ru.json';
import zh from './locales/zh.json';
import ja from './locales/ja.json';
import es from './locales/es.json';

const resources = {
  en,
  ru,
  zh,
  ja,
  es,
};

// declare custom type options so the return is always a string.

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['cookie', 'localStorage'],
    },
    resources,
    fallbackLng: config.default_language,
    keySeparator: '.',
    returnNull: false,
  });

export default i18n;
