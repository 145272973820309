import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { generateRandomId } from './utils/generateRandomId';
import { useFetchData } from 'shared/hooks/useFetchData';
import api from 'shared/api';
import { Variable } from 'shared/types/variable';
import packageJson from '../../package.json';
interface AppContextState {
  serverSentEventId: number;
  setServerSentEventId: Dispatch<SetStateAction<number>>;
  showTooltipMarkers: boolean;
  setShowTooltipMarkers: Dispatch<SetStateAction<boolean>>;
  dynamicRecsLoading: boolean;
  setDynamicRecsLoading: Dispatch<SetStateAction<boolean>>;
  targets: Variable[];
  selectedTarget: string;
  setSelectedTarget: Dispatch<SetStateAction<string>>;
}

const defaultState: AppContextState = {
  serverSentEventId: generateRandomId(),
  setServerSentEventId: () => {},
  showTooltipMarkers: false,
  setShowTooltipMarkers: () => {},
  dynamicRecsLoading: false,
  setDynamicRecsLoading: () => {},
  targets: [],
  selectedTarget: '',
  setSelectedTarget: () => {},
};

export const AppContext = React.createContext<AppContextState>(defaultState);

const getVariables = async () => await api.get('/namespaces');

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [serverSentEventId, setServerSentEventId] = useState(
    defaultState.serverSentEventId,
  );

  const [showTooltipMarkers, setShowTooltipMarkers] = useState(
    defaultState.showTooltipMarkers,
  );

  const [dynamicRecsLoading, setDynamicRecsLoading] = useState(false);

  const [targets] = useFetchData<Variable[]>(getVariables, []);
  const [selectedTarget, setSelectedTarget] = useState<string>('');

  useEffect(() => {
    if (!selectedTarget && targets.length > 0) setSelectedTarget(targets[0].id);
  }, [targets, selectedTarget]);

  console.log(`App version - ${packageJson.version}`);

  return (
    <AppContext.Provider
      value={{
        serverSentEventId,
        setServerSentEventId,
        showTooltipMarkers,
        setShowTooltipMarkers,
        dynamicRecsLoading,
        setDynamicRecsLoading,
        targets,
        selectedTarget,
        setSelectedTarget,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
