import React, { useEffect } from 'react';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import './SwaggerUIOverrides.scss';
import { env } from '../../env';

const domNodeId = 'open-api-documentation';

const OpenApiDocumentation = () => {
  const initializeDocumentation = async () => {
    SwaggerUI({
      url: `${env.REACT_APP_COMMODITY_ANALYTICS_API ?? ''}/open_api`,
      dom_id: `#${domNodeId}`,
      docExpansion: 'list',
    });
  };

  useEffect(() => {
    initializeDocumentation();
  }, []);

  return <div id={domNodeId} />;
};

export default OpenApiDocumentation;
