import { Dispatch, SetStateAction, useState, useRef, useEffect } from 'react';

import Skeleton from 'components/Skeleton/Skeleton';

import { getBacktestingVideo } from 'service/backtesting.service';
import Notifier from 'shared/services/Notifier/Notifier';

interface VideoProps {
  target: string;
  setIsVideoLoading: Dispatch<SetStateAction<boolean>>;
}

const BacktestingVideo = ({ target, setIsVideoLoading }: VideoProps) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [width, setWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    async function fetchVideo() {
      setVideoUrl('');
      try {
        const videoData = await getBacktestingVideo(target, signal);
        const url = URL.createObjectURL(videoData);

        setVideoUrl(url);
      } catch (error) {
        console.error(error);
        // Notifier.show('Error trying to get video.', { type: 'error' });
      }
    }

    fetchVideo();

    return () => controller.abort();
  }, [target]);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <>
      {videoUrl ? (
        <div style={{ width: '100%', marginTop: '24px' }} ref={ref}>
          <video
            controls
            width={width.toString()}
            onLoadedData={() => {
              setIsVideoLoading(false);
            }}
          >
            <source src={videoUrl} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <Skeleton height={250} />
      )}
    </>
  );
};

export default BacktestingVideo;
