import classNames from 'classnames';
import { ReactNode } from 'react';
import { Row } from 'react-table';
import config from '../../../application.json';
import { doesRecommendationHasChanges } from 'shared/utils/tableHelper';
import ExpandContent from '../../ExpandContent/ExpandContent';
import styles from './TableRow.module.scss';

interface RowData {
  isLoading?: boolean;
  [x: string]: any;
}

interface Props {
  row: Row<RowData>;
  rowIndex: number;
  expandedRowBody?: (row: Row<RowData>) => ReactNode;
  style?: Record<string, any>;
  rowClassName?: string;
  getRowStyles?: (row: Row<RowData>) => { [name: string]: string };
  getCellProps?: (cell: any) => { [name: string]: string };
}
const defaultPropGetter = () => ({});
const TableRow = ({
  row,
  rowIndex,
  expandedRowBody,
  style,
  rowClassName,
  getRowStyles = defaultPropGetter,
  getCellProps = defaultPropGetter,
}: Props) => {
  return (
    <div className={styles.container} data-table-row-id={row.id}>
      <div
        {...(!row.isSelected && row.getToggleRowExpandedProps())}
        style={{
          ...style,
          ...getRowStyles(row),
          background: 'rgba(0, 0, 0, 0)',
        }}
        className={classNames(
          styles.content,
          row.original.isLoading && styles.skeletonLoader,
          row.isSelected && styles.rowSelected,
          row.isExpanded && styles.rowExpanded,
          rowClassName,
        )}
      >
        {row.cells.map((cell: any, index: number) => (
          <div
            {...cell.getCellProps([
              {
                className: styles.cell,
                style: {
                  width: 0,
                  flex: '1 0 auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
              getCellProps(cell),
            ])}
            data-test-id={cell.column.id}
          >
            <span>{cell.render('Cell')}</span>
          </div>
        ))}

        {(row.original.isLoading ||
          (config.fade_out_minor_recommendations &&
            !doesRecommendationHasChanges(row.original))) && (
          <div className={styles.inactive} />
        )}
      </div>

      {expandedRowBody && (
        <ExpandContent expand={row.isExpanded} duration={500}>
          {expandedRowBody(row)}
        </ExpandContent>
      )}
    </div>
  );
};

export default TableRow;
