import { select } from 'd3-selection';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './ExpandContent.module.scss';

interface Props {
  children: ReactNode;
  expand: boolean;
  duration?: number;
}

// Wrapper for playing expand / collapse animation for content with dynamic height
// Avoid changing content height while playing animation
// Avoid any overflow items inside content while playing animation - they will be cut

const ExpandContent = ({ children, expand, duration = 1000 }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  const animate = useCallback(
    shouldExpand => {
      if (contentRef.current && containerRef.current) {
        const contentHeight = contentRef.current.clientHeight;

        if (shouldExpand) {
          select(containerRef.current)
            .style('overflow', 'hidden')
            .transition()
            .duration(duration)
            .style('height', contentHeight + 'px')
            .on('end', () => {
              if (containerRef.current) {
                containerRef.current.style.height = 'auto';
                select(containerRef.current).style('overflow', 'visible');
                containerRef.current.scrollIntoView({
                  block: 'nearest',
                  behavior: 'smooth',
                });
              }
            });
        } else {
          select(containerRef.current)
            .style('overflow', 'hidden')
            .style('height', contentHeight + 'px')
            .transition()
            .duration(duration)
            .style('height', '0px')
            .on('end', () => {
              setVisible(false);
              if (containerRef.current) {
                select(containerRef.current).style('overflow', 'visible');
              }
            });
        }
      }
    },
    [duration],
  );

  useEffect(() => {
    if (expand) setVisible(true);
    // here we are skipping one tick in event loop to let node be placed in DOM and make our ref available for use
    setTimeout(() => animate(expand));
  }, [expand, animate]);

  if (visible) {
    return (
      <div ref={containerRef} className={styles.container}>
        <div ref={contentRef}>{children}</div>
      </div>
    );
  }

  return null;
};

export default ExpandContent;
