import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './Tooltip.module.scss';
import TooltipBody from './TooltipBody';

interface Props {
  children: React.ReactNode;
  text: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  position?: 'top' | 'bottom';
}

const Tooltip = ({
  children,
  text,
  className,
  containerClassName,
  position = 'top',
  disabled = false,
}: Props) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div
      className={classNames(styles.container, containerClassName)}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}

      {!disabled && isVisible && (
        <TooltipBody text={text} position={position} className={className} />
      )}
    </div>
  );
};

export default Tooltip;
