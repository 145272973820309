import { ReactNode } from 'react';
import styles from './Subheader.module.scss';

interface Props {
  title: string | ReactNode;
  children: ReactNode;
}

const Subheader = ({ title, children }: Props) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.inputs}>{children}</div>
    </div>
  );
};

export default Subheader;
