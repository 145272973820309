import api, { apiv2 } from 'shared/api';

export const getBacktestingVideo = async (
  target: string,
  signal: AbortSignal,
) => {
  const API_URL = `backtesting/video/${target}`;
  const { data } = await api.get(API_URL, { responseType: 'blob', signal });

  return data;
};

export const getBacktestingMetrics = async (namespace: string) => {
  const { data } = await apiv2.get(`model/${namespace}/backtesting/metrics`);

  return {
    ...data,
    metrics_series: data.metrics_series,
    backtest_results_curves: JSON.parse(data.backtest_results_curves),
    summary: JSON.parse(data.summary),
  };
};

export const getPricePosition = async (namespace: string) => {
  const { data } = await apiv2.get(
    `model/price_position/${namespace}/backtest`,
  );

  return data;
};

export const getPriceDirection = async (namespace: string) => {
  const { data } = await apiv2.get(
    `model/price_direction/${namespace}/backtest`,
  );

  return data;
};
