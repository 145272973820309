import classNames from 'classnames';
import React from 'react';
import styles from './Checkbox.module.scss';

interface Props {
  label?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  disabled?: boolean;
  preventBubbling?: boolean;
  labelClassName?: string;
}

const Checkbox = ({
  checked = false,
  onChange,
  label,
  disabled = false,
  labelClassName,
  preventBubbling = true,
}: Props) => (
  <label
    className={classNames(styles.container, disabled && styles.disabled)}
    onClick={(e: React.MouseEvent<HTMLElement>) =>
      preventBubbling && e.stopPropagation()
    }
  >
    {label && (
      <span
        className={classNames(styles.label, labelClassName && labelClassName)}
      >
        {label}
      </span>
    )}
    <input
      className={styles.input}
      type="checkbox"
      onChange={onChange && !disabled ? onChange : () => {}}
      checked={checked}
    />
    <span className={styles.checkbox} />
  </label>
);

export default Checkbox;
