import ErrorHandler from './ErrorHandler';

export const callWithErrorHandler = (func: () => void) => {
  try {
    func();
  } catch (e) {
    console.log({ error: e });
    ErrorHandler.sendError(e);
  }
};
