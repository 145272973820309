export const PRICE_COLOR = 'rgba(0, 180, 255, .7)';
export const INCREASE_COLOR = 'rgba(8, 189, 186, .7)';
export const DECREASE_COLOR = 'rgba(230, 86, 86, .7)';
export const PAPER_BG_COLOR = 'rgba(0,0,0,0)';
export const PLOT_BG_COLOR = 'transparent';
export const axisfont = {
  family: 'TitilliumWeb, sans-serif',
  size: 16,
  color: 'white',
};

export const defaultLayout = {
  plot_bgcolor: PLOT_BG_COLOR,
  paper_bgcolor: PAPER_BG_COLOR,
  font: {
    color: 'white',
  },
};

function cleanString(str: string): string {
  // Remove whitespace from both ends of the string
  let cleanedStr = str.trim();

  // Replace all whitespace characters and dots with underscores
  cleanedStr = cleanedStr.replace(/[\s.]+/g, '_');

  // Return the cleaned string
  return cleanedStr;
}

export function mapDataFrametoTableProps(
  dataframe: any,
  emptyIndexHeader = false,
) {
  const data = dataframe.data.map((row: any[], rowId: number) => {
    const obj: Record<string, unknown> = {};
    dataframe.columns.forEach((colName: string, idx: number) => {
      obj[cleanString(colName)] = row[idx];
    });

    obj['id'] = rowId;

    return obj;
  });

  const columns = dataframe.columns.map((col: string) => ({
    Header: col,
    accessor: cleanString(col),
  }));

  // Empty index to emulate pandas dataframe
  if (emptyIndexHeader) {
    columns['index'] = {
      ...columns['index'],
      Header: '',
    };
  }

  const props = { data, columns };

  return props;
}
