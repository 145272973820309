export const getFormattedTime = (date: Date, locale?: string): string => {
  const [time, period = ''] = date
    .toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(' ');

  return `${time} ${period.toLowerCase()}`;
};

export const getFormattedDate = (date: Date, locale?: string): string =>
  date.toLocaleDateString(locale, {
    month: 'short',
    day: 'numeric',
  });

export const getFormattedShortDateTime = (
  date: Date,
  locale?: string,
): string =>
  date.toLocaleDateString(locale, {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

export const getFormattedFullDateTime = (date: Date, locale?: string): string =>
  date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

export const getFormattedDateWithYear = (
  date: Date,
  locale: string | undefined,
): string =>
  date.toLocaleDateString(locale, {
    year: 'numeric',
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });

export const getFormattedDateWithWeekday = (
  date: Date,
  locale: string | undefined,
): string =>
  date.toLocaleDateString(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });

export const getISODateWithoutMilliseconds = (date?: Date) =>
  `${date?.toISOString().split('.')[0]}Z`;

export const getDayStart = (date: Date) =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
  );

export const getDayEnd = (date: Date) =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
  );
