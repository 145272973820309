const sanitizer = document.createElement('div');

// Sanitize and encode all HTML in a user-submitted string
// this prevents XSS attacks when injecting strings in DOM nodes
function sanitizeHTML(str: string) {
  sanitizer.textContent = str;
  return sanitizer.innerHTML;
}

export default sanitizeHTML;
