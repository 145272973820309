import { ReactNode } from 'react';

import styles from './PageSubtitle.module.scss';

type PageSubtitleProps = {
  children: ReactNode;
};

const PageSubtitle = ({ children }: PageSubtitleProps) => (
  <h3 className={styles.title}>{children}</h3>
);

export default PageSubtitle;
