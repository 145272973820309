import classNames from 'classnames';
import React from 'react';
import styles from './Tooltip.module.scss';

interface Props {
  text: string;
  position?: 'top' | 'bottom';
  className?: string;
}

const TooltipBody = ({ text, position = 'top', className }: Props) => (
  <div
    className={classNames(
      position === 'top' ? styles.topTooltip : styles.bottomTooltip,
      className,
    )}
  >
    {text}
  </div>
);

export default TooltipBody;
