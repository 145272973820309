import CustomLegend from 'components/CustomLegend/CustomLegend';
import Plot from 'react-plotly.js';
import { PAPER_BG_COLOR, PLOT_BG_COLOR } from 'shared/utils/plotly';

interface FeatureImportanceProps {
  data: any;
}

const FeatureImportance = ({ data }: FeatureImportanceProps) => {
  const N = 12;
  const x = data.x.slice(-N, -1);
  const y = data.y.slice(-N, -1);
  const text = data.text.slice(-N, -1);

  const heigth = N * 35 + 12;

  const legends = [
    {
      text: 'Supply',
      color: 'rgba(94, 157, 255, .7)',
    },
    {
      text: 'Market',
      color: 'rgba(255, 146, 34, .7)',
    },
    {
      text: 'Demand',
      color: 'rgba(104, 159, 56, .7)',
    },
  ];

  return (
    <>
      <CustomLegend legends={legends} />
      <Plot
        data={[
          {
            x: x,
            y: y,
            text: text,
            type: 'bar',
            orientation: 'h',
            textposition: 'auto',
            marker: {
              color: data.marker_color,
            },
            showlegend: false,
          },
          {
            x: [null],
            y: [null],
            type: 'scatter',
            mode: 'markers',
            name: 'Supply',
            marker: {
              color: legends[0].color,
              symbol: 'square',
            },
            showlegend: true,
          },
          {
            x: [null],
            y: [null],
            type: 'scatter',
            mode: 'markers',
            name: 'Market',
            marker: {
              color: legends[1].color,
              symbol: 'square',
            },
            showlegend: true,
          },
          {
            x: [null],
            y: [null],
            type: 'scatter',
            mode: 'markers',
            name: 'Demand',
            marker: {
              color: legends[2].color,
              symbol: 'square',
            },
            showlegend: true,
          },
        ]}
        layout={{
          showlegend: false,
          height: heigth,
          width: window.innerWidth - 310,
          paper_bgcolor: PAPER_BG_COLOR,
          plot_bgcolor: PLOT_BG_COLOR,
          margin: {
            t: 8,
          },
          font: {
            color: 'white',
          },
          xaxis: {
            automargin: true,
            linecolor: 'rgba(255,255,255,.5)',
            gridcolor: 'rgba(255,255,255,.5)',
          },
          yaxis: {
            automargin: true,
            linecolor: 'rgba(255,255,255,.5)',
          },
        }}
      />
    </>
  );
};

export default FeatureImportance;
