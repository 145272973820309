import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import styles from './PopupMenu.module.scss';

export interface PopupMenuProps {
  button: React.ReactNode;
  children?: React.ReactNode;
  tooltip?: string;
  tooltipPosition?: 'top' | 'bottom';
  xAlign?: 'left' | 'right';
  yAlign?: 'top' | 'bottom';
  opened?: boolean;
  onToggle?: (isOpen: boolean) => void;
  testKey?: string;
  childrenClassName?: string;
  buttonClassName?: string;
  highlightClassName?: string;
}

const PopupMenu = ({
  button,
  buttonClassName,
  highlightClassName,
  tooltip,
  tooltipPosition = 'bottom',
  xAlign = 'right',
  yAlign = 'bottom',
  children,
  onToggle,
  testKey = '',
  opened = false,
  childrenClassName,
}: PopupMenuProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(opened);
  const [isHighlighted, setHighlighted] = useState(false);

  useEffect(() => setOpen(opened), [opened]);

  useEffect(() => {
    if (onToggle) onToggle(isOpen);
  }, [isOpen, onToggle]);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const hideMenu = (e: MouseEvent) => {
      if (
        !buttonRef.current ||
        !buttonRef.current.contains(e.target as Element)
      ) {
        setOpen(false);
        setHighlighted(false);
      }
    };

    document.addEventListener('click', hideMenu);
    return () => document.removeEventListener('click', hideMenu);
  }, [setOpen]);

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setHighlighted(true)}
      onMouseLeave={() => setHighlighted(false)}
    >
      <Tooltip
        disabled={!tooltip}
        text={tooltip || ''}
        position={tooltipPosition}
        className={styles.tooltip}
      >
        <div
          ref={buttonRef}
          data-testid={testKey && testKey + '-button'}
          onClick={() => setOpen(!isOpen)}
          className={classNames(
            styles.button,
            buttonClassName,
            (isOpen || isHighlighted) && highlightClassName,
          )}
        >
          {button}
        </div>
      </Tooltip>
      {isOpen && (
        <div
          data-testid={testKey && testKey + '-content'}
          className={classNames(
            styles.childrenWrapper,
            xAlign === 'left' && styles.childrenWrapperAlignLeft,
            yAlign === 'top' && styles.childrenWrapperAlignTop,
            childrenClassName,
          )}
          ref={contentRef}
        >
          {children && children}
        </div>
      )}
    </div>
  );
};

export default PopupMenu;
