import { useContext, useEffect, useRef, useState } from 'react';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Subheader from 'components/Subheader/Subheader';
import Dropdown from 'components/Dropdown/Dropdown';
import Button from 'components/Button/Button';
import ContainerBlock from 'components/ContainerBlock/ContainerBlock';
import Skeleton from 'components/Skeleton/Skeleton';
import SimulatorResults, {
  SimulationParameters,
  SimulationResults,
} from './SimulatorResults';
import {
  BacktestingResults,
  BacktestingResultsProps,
} from './SimulatorBacktesting';
import SimulatorForm, { SimulatorProps } from './SimulatorForm';

import styles from './Simulator.module.scss';

import { AppContext } from 'shared/AppContext';
import { useCommodityTarget } from 'shared/hooks/useCommodityTarget';
import {
  getBacktestingResult,
  getSimulatorGraph,
  getSimulatorProps,
  simulateResults,
} from 'service/simulation.service';
import {
  downloadFeatureImpact,
  downloadNewSimulatedPrice,
  downloadSimulatorReport,
} from 'service/download.service';
import Notifier from 'shared/services/Notifier/Notifier';

type LoadingState = {
  sliders: boolean;
  graph: boolean;
  feature_explorer: boolean;
  backtesting: boolean;
};

const initialLoadingState: LoadingState = {
  sliders: true,
  graph: true,
  feature_explorer: true,
  backtesting: true,
};

const Simulator = () => {
  const { target, targets, selectedTarget, setSelectedTarget } =
    useCommodityTarget();
  const { serverSentEventId } = useContext(AppContext);
  const { t } = useTranslation();
  const backtestingRef = useRef<HTMLDivElement>(null);

  const [simulatorProps, setSimulatorProps] = useState<SimulatorProps>();
  const [graphHtml, setGraphHTML] = useState<string>('');
  const [loadingResults, setLoading] = useState(false);
  const [isDownloadingSimulation, setIsDownloadingSimulation] = useState(false);
  const [simulationResults, setSimulationResults] =
    useState<SimulationResults>();
  const [backtestingResults, setBacktestingResults] =
    useState<BacktestingResultsProps>();
  const [loadingState, setLoadingState] =
    useState<LoadingState>(initialLoadingState);
  const [error, setError] = useState<boolean>(false);
  const [simulationParameters, setSimulatorParameters] =
    useState<SimulationParameters>({});

  const namespace = target?.simulator;
  document.title = t('Simulator.title');

  useEffect(() => {
    if (!namespace) return;

    const fetchInitialData = async () => {
      setError(false);
      setLoadingState(initialLoadingState);

      try {
        const [
          graphData,
          { simulatorPropsData, simulatorParametersData },
          backtestingResultData,
        ] = await Promise.all([
          getSimulatorGraph(namespace),
          getSimulatorProps(namespace),
          getBacktestingResult(namespace),
        ]);

        setGraphHTML(graphData.html);
        setSimulatorProps(simulatorPropsData);
        setSimulatorParameters(simulatorParametersData);
        setBacktestingResults(backtestingResultData);
      } catch (error) {
        console.error(error);
        // Notifier.show('Error trying to download file', { type: 'error' });
        setError(true);
      } finally {
        setLoadingState({
          sliders: false,
          graph: false,
          feature_explorer: false,
          backtesting: false,
        });
      }
    };
    fetchInitialData();
  }, [serverSentEventId, target]);

  const handleSimulate = async () => {
    if (!target) return;
    try {
      setLoading(true);
      const simulationResponseData = await simulateResults(
        target,
        simulationParameters,
      );
      setSimulationResults(simulationResponseData);
    } catch (error) {
      // alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSimulationResults = async () => {
    setIsDownloadingSimulation(true);

    try {
      const simulationFile = await downloadNewSimulatedPrice(simulationResults);
      FileSaver.saveAs(simulationFile);
    } catch (error) {
      // alert('Error trying to download file');
      console.error('Error trying to download file', error);
      // Notifier.show('Error trying to download file', { type: 'error' });
    } finally {
      setIsDownloadingSimulation(false);
    }
  };

  const handleDownloadFeatureImpact = async () => {
    setIsDownloadingSimulation(true);

    try {
      const featImpactFile = await downloadFeatureImpact(simulationResults);
      FileSaver.saveAs(featImpactFile);
    } catch (error) {
      // alert('Error trying to download file');
      console.error('Error trying to download file', error);
      // Notifier.show('Error trying to download file', { type: 'error' });
    } finally {
      setIsDownloadingSimulation(false);
    }
  };

  const handleDownloadSimulatorReport = async () => {
    setIsDownloadingSimulation(true);

    try {
      const simReportFile = await downloadSimulatorReport(simulationResults);
      FileSaver.saveAs(simReportFile);
    } catch (error) {
      // alert('Error trying to download file');
      console.error('Error trying to download file', error);
      // Notifier.show('Error trying to download file', { type: 'error' });
    } finally {
      setIsDownloadingSimulation(false);
    }
  };

  const handleSeeBacktesting = () => {
    if (!backtestingRef?.current) return;
    backtestingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  if (error)
    return (
      <>
        <Subheader title={t('Simulator.title')}>
          <div className={styles.dropdownContainer}>
            <Dropdown
              options={targets}
              select={setSelectedTarget}
              selected={selectedTarget}
            />
          </div>
        </Subheader>
        <p className={styles.moduleUnderDevelopment}>
          {t('ModuleUnderDevelopment')}
        </p>
      </>
    );

  return (
    <>
      <Subheader title={t('Simulator.title')}>
        <div className={styles.dropdownContainer}>
          <Dropdown
            options={targets}
            select={setSelectedTarget}
            selected={selectedTarget}
          />
        </div>
      </Subheader>
      <button onClick={handleSeeBacktesting} className={styles.bcktBtn}>
        {t('Forecasting.goToBacktesting')}
        <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />
      </button>
      <br />
      {loadingResults && (
        <>
          <Skeleton height={200} />
          <Skeleton height={200} />
          <Skeleton height={200} />
        </>
      )}

      {!loadingResults && simulationResults && (
        <SimulatorResults
          {...simulationResults}
          isDownloadingSimulation={isDownloadingSimulation}
          onDownloadSimulationResults={handleDownloadSimulationResults}
          onDownloadFeatureImpact={handleDownloadFeatureImpact}
          onDownloadSimulatorReport={handleDownloadSimulatorReport}
        />
      )}

      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Simulation</h3>
          <Button
            disabled={
              loadingResults || Object.values(loadingState).some(Boolean)
            }
            isLoading={loadingResults}
            onClick={handleSimulate}
          >
            Simulate Scenario
          </Button>
        </div>
        <p className={styles.containerBlockSubtitle}>
          Select the variables you want to use, define a value for each and
          generate your simulation.
        </p>
        {Object.values(loadingState).some(Boolean) ||
        !simulationParameters ||
        !simulatorProps ||
        !target ? (
          <Skeleton height={450} />
        ) : (
          <SimulatorForm
            simulatorProps={simulatorProps}
            simulationParameters={simulationParameters}
            onSimulationParametersChange={setSimulatorParameters}
          />
        )}
      </ContainerBlock>

      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Causal graph</h3>
        </div>
        {graphHtml ? (
          <div dangerouslySetInnerHTML={{ __html: graphHtml }}></div>
        ) : (
          <Skeleton height={450} />
        )}
      </ContainerBlock>

      <ContainerBlock>
        <div className={styles.containerBlockHeader} ref={backtestingRef}>
          <h3>Backtesting results</h3>
        </div>
        <p className={styles.containerBlockSubtitle}>
          Composite Commodity
          <br />
          Future Continuat
        </p>
        <br />
        {backtestingResults ? (
          <BacktestingResults {...backtestingResults} />
        ) : (
          <Skeleton height={450} />
        )}
      </ContainerBlock>
      {/*
      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Feature Explorer</h3>
        </div>
        <br />
        {features ? (
          <Plot
            config={{
              displayModeBar: false,
              displaylogo: false,
            }}
            useResizeHandler={true}
            style={{ height: '750px' }}
            data={features.series.map((item: any) => ({
              x: item.date,
              name: item.name,
              y: item.value,
              mode: 'lines',
            }))}
            layout={{
              paper_bgcolor: 'transparent',
              plot_bgcolor: 'transparent',
              legend: {
                orientation: 'h',
              },
              margin: {
                t: 0,
                r: 0,
              },
              font: {
                color: 'white',
              },
              yaxis: {
                linecolor: 'rgba(255,255,255,.5)',
                gridcolor: 'rgba(255,255,255,.5)',
                title: {
                  text: '',
                },
              },
              xaxis: {
                linecolor: 'rgba(255,255,255,.5)',
                gridcolor: 'rgba(255,255,255,.5)',
                title: {
                  text: '',
                },
              },
            }}
          />
        ) : (
          <Skeleton height={450} />
        )}
      </ContainerBlock> */}
    </>
  );
};

export default Simulator;
