import styles from './ErrorView.module.scss';
import sanitizeHTML from '../../utils/sanitizeHTML';

function ErrorView(message) {
  return `
      <div class='${styles.wrapper}'>
        <div class='${styles.body}'>
          <div class='${styles.title}'>${
            message ? sanitizeHTML(message) : 'Something went wrong!'
          }</div>
          <div class='${styles.subtitle}'>
            please reach to
            <span class='${styles.email}'>support@helpdesk.com</span>
          </div>
        </div>
      </div>
    `;
}

export default ErrorView;
