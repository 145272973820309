import React from 'react';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  icon: React.ReactNode;
  action?: () => void;
  label?: React.ReactNode | string;
}

const IconButton = ({ icon, label, action }: IconButtonProps) => {
  return (
    <div className={styles.container} onClick={action && action}>
      <div className={styles.icon}>{icon}</div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

export default IconButton;
