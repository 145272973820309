import axios from 'axios';
import tokenInterceptor from './tokenInterceptor';
import { env } from '../../env';

export const getApiUrl = (apiVersion = 'v1') => {
  //To fix, in pipeline with envs
  const apiUrl = env.REACT_APP_COMMODITY_ANALYTICS_API;

  if (apiUrl === undefined) {
    throw new Error(
      'You have not provided REACT_APP_COMMODITY_ANALYTICS_API environment variable',
    );
  }

  return `${apiUrl}/api/${apiVersion}`;
};

const api = axios.create({
  baseURL: getApiUrl(),
});

export const apiv2 = axios.create({
  baseURL: getApiUrl('v2'),
});

api.interceptors.request.use(tokenInterceptor);
apiv2.interceptors.request.use(tokenInterceptor);

export default api;
