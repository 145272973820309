import { useState, useEffect } from 'react';

import InputSlider from './InputSlider';
import CheckBox from '../Checkbox/Checkbox';
import { SimulationParameters } from 'pages/Simulator/SimulatorResults';

import styles from './SimulationController.module.scss';

export interface NodeControllerProps {
  description: string;
  unit: string;
  node: string;
  latest_value: number;
  min_value: number;
  max_value: number;
  marginRight?: string;
  defaultValue: number;
  simulationParameters: SimulationParameters;
  handleNodeChange: any;
  setIsChecked: any;
}

export function SimulatorController({
  description,
  unit,
  latest_value,
  min_value,
  max_value,
  node,
  defaultValue,
  simulationParameters,
  handleNodeChange,
  setIsChecked,
}: NodeControllerProps) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [_value, setValue] = useState<number>(simulationParameters[node].value);

  const value = simulationParameters[node].value;
  const isChecked = simulationParameters[node].used;
  const width = (window.innerWidth - 400) / 2;

  useEffect(() => {
    setValue(simulationParameters[node].value);
    setIsDisabled(simulationParameters[node].classification === 'unused');
  }, [simulationParameters[node]]);

  const dispatchChange = (inputValue: number) => {
    const _round = (n: number) => parseFloat(n.toFixed(2));
    inputValue = _round(inputValue);
    const newValue = Math.max(
      Math.min(inputValue, _round(max_value)),
      _round(min_value),
    );
    setValue(newValue);
    handleNodeChange(node, newValue);
  };

  return (
    <fieldset
      disabled={isDisabled}
      className={styles.fieldset}
      style={isChecked ? { marginBottom: '24px' } : undefined}
    >
      <div
        className={styles.wrapper}
        style={isChecked ? { marginBottom: '30px' } : undefined}
      >
        <div className={styles.line}>
          <CheckBox
            onChange={() => setIsChecked(node)}
            checked={isChecked}
            label={`${description} [${unit}] - Today ${latest_value.toFixed(
              2,
            )}`}
          />
        </div>
        {isChecked && (
          <input
            className={styles.textIpt}
            type="text"
            value={isNaN(_value) ? '' : _value}
            min={min_value}
            max={max_value}
            step="any"
            onChange={({ target }) => {
              setValue(parseFloat(target.value));
            }}
            onBlur={evt => {
              let inputValue = +evt.target.value;
              if (isNaN(inputValue)) {
                inputValue = defaultValue;
              }
              dispatchChange(inputValue);
            }}
            onKeyDown={e => {
              const key = e.key;
              if (['ArrowUp', 'ArrowDown'].includes(key)) {
                e.preventDefault();
                const step = (max_value - min_value) / 100;
                const newValue = _value + (key === 'ArrowUp' ? step : -step);
                dispatchChange(newValue);
              }
              if (key === 'Enter') {
                let inputValue = _value;
                if (isNaN(inputValue)) {
                  inputValue = defaultValue;
                }
                dispatchChange(inputValue);
              }
            }}
          />
        )}
      </div>
      {isChecked && (
        <InputSlider
          min={min_value}
          max={max_value}
          value={value}
          trackWidth={width}
          onChange={x => handleNodeChange(node, parseFloat(x.toFixed(2)))}
          disable={isDisabled}
        />
      )}
    </fieldset>
  );
}
