import { AxiosRequestConfig } from 'axios';

import api, { apiv2 } from 'shared/api';

export const downloadForecastCurve = async (
  namespace: string,
  date: Date,
  sigma: number,
): Promise<Blob> => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `forecasting/${namespace}/download`,
    responseType: 'arraybuffer',
    data: {
      prediction_made_on: date!.toISOString().split('T')[0],
      sigma: sigma,
    },
    headers: { 'Content-Type': 'application/json' },
  };

  const file = await apiv2.request(config);
  const fileBlob = new File(
    [file.data],
    `price-prediction-curve-${Date.now()}.xlsx`,
    {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  );

  return fileBlob;
};

export const downloadFeatureImportance = async (
  namespace: string,
  date: Date,
): Promise<Blob> => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `forecasting/${namespace}/downloadfeatureimportance`,
    responseType: 'arraybuffer',
    data: {
      prediction_made_on: date!.toISOString().split('T')[0],
    },
    headers: { 'Content-Type': 'application/json' },
  };

  const file = await apiv2.request(config);
  const fileBlob = new File(
    [file.data],
    `feature-importance-${Date.now()}.xlsx`,
    {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  );

  return fileBlob;
};

export const downloadFeatureContribution = async (
  namespace: string,
  date: Date,
): Promise<Blob> => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `forecasting/${namespace}/downloadfeaturecontribution`,
    responseType: 'arraybuffer',
    data: {
      prediction_made_on: date!.toISOString().split('T')[0],
    },
    headers: { 'Content-Type': 'application/json' },
  };

  const file = await apiv2.request(config);
  const fileBlob = new File(
    [file.data],
    `feature-contibution-${Date.now()}.xlsx`,
    {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  );

  return fileBlob;
};

export const downloadBacktesting = async (namespace: string): Promise<Blob> => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `model/${namespace}/backtesting/downloadmetrics`,
    responseType: 'arraybuffer',
    headers: { 'Content-Type': 'application/json' },
  };

  const file = await api.request(config);
  const fileBlob = new File([file.data], `backtesting-${Date.now()}.xlsx`, {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return fileBlob;
};

export const downloadNewSimulatedPrice = async (
  simulationResults: any,
): Promise<Blob> => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `simulator/downloadsimulatedprice`,
    responseType: 'arraybuffer',
    data: {
      ...simulationResults,
    },
    headers: { 'Content-Type': 'application/json' },
  };

  const file = await api.request(config);
  const fileBlob = new File([file.data], `simulated-price-${Date.now()}.xlsx`, {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return fileBlob;
};

export const downloadFeatureImpact = async (
  simulationResults: any,
): Promise<Blob> => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `simulator/downloadfeatureimpact`,
    responseType: 'arraybuffer',
    data: {
      ...simulationResults,
    },
    headers: { 'Content-Type': 'application/json' },
  };

  const file = await api.request(config);
  const fileBlob = new File(
    [file.data],
    `simulated-feature-impact-${Date.now()}.xlsx`,
    {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  );

  return fileBlob;
};

export const downloadSimulatorReport = async (
  simulationResults: any,
): Promise<Blob> => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `simulator/downloadreport`,
    responseType: 'arraybuffer',
    data: {
      ...simulationResults,
    },
    headers: { 'Content-Type': 'application/json' },
  };

  const file = await api.request(config);
  const fileBlob = new File(
    [file.data],
    `simulated-report-${Date.now()}.xlsx`,
    {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  );

  return fileBlob;
};
