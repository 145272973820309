import { Data } from 'plotly.js';
import Plot from 'react-plotly.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import {
  mapDataFrametoTableProps,
  PRICE_COLOR,
  DECREASE_COLOR,
  INCREASE_COLOR,
  PAPER_BG_COLOR,
  PLOT_BG_COLOR,
} from 'shared/utils/plotly';

import Table from 'components/Table/Table';
import ContainerBlock from 'components/ContainerBlock/ContainerBlock';

import styles from './Simulator.module.scss';
import DownloadButton from 'components/DownloadButton/DownloadButton';

export interface SimulationResults {
  todays_date: string;
  simulation_date: string;
  price_description: string;
  price_units: string;
  pred_window: number;
  target_pct_diff: number;
  y_latest: number;
  y_pred: number;
  y_ci_low: number;
  y_ci_high: number;
  kde: any;
  df_display: any;
  feature_impact_trace: Record<string, any>;
  price_series: any;
  isDownloadingSimulation: boolean;
  onDownloadSimulationResults: () => void;
  onDownloadFeatureImpact: () => void;
  onDownloadSimulatorReport: () => void;
}

export const DefaultSimulationResults = {
  todays_date: '',
  simulation_date: '',
  price_description: '',
  price_units: '',
  pred_window: 0,
  target_pct_diff: 0,
  y_latest: 0,
  y_pred: 0,
  y_ci_low: 0,
  y_ci_high: 0,
  kde: {},
  df_display: {},
  feature_impact_trace: {},
  price_series: {},
};

function RelativeChange({ change }: { change: number }) {
  const color = change >= 0 ? '#689F3850' : '#78353050';
  const icon = change >= 0 ? faArrowUp : faArrowDown;

  return (
    <span
      className={styles.relativeChangeWrapper}
      style={{ backgroundColor: color }}
    >
      <FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} />
      {change.toFixed(1)}%
    </span>
  );
}

export interface SimulationParameter {
  value: number;
  used: boolean;
  classification: string;
}

export type SimulationParameters = Record<string, SimulationParameter>;

function SimulationResults({
  price_description,
  price_units,
  pred_window,
  target_pct_diff,
  y_latest,
  y_pred,
  y_ci_low,
  y_ci_high,
  kde,
  df_display,
  feature_impact_trace,
  price_series,
  todays_date,
  simulation_date,
  isDownloadingSimulation,
  onDownloadSimulationResults,
  onDownloadFeatureImpact,
  onDownloadSimulatorReport,
}: SimulationResults) {
  const dfDisplayProps = mapDataFrametoTableProps(df_display);
  const getCellProps = (cellInfo: any) => {
    if (['Diff_(%)', 'Diff_from_today'].includes(cellInfo.column.id)) {
      return {
        style: {
          background: cellInfo.value > 0 ? INCREASE_COLOR : DECREASE_COLOR,
        },
      };
    }
    return {};
  };

  const waterFallTrace = {
    ...feature_impact_trace,
    name: 'Feature Impact',
    orientation: 'h',
    type: 'waterfall',
    decreasing: { marker: { color: DECREASE_COLOR } },
    increasing: { marker: { color: INCREASE_COLOR } },
    totals: { marker: { color: PRICE_COLOR } },
    textposition: 'auto',
    connector: {
      mode: 'spanning',
      line: { width: 0.5, color: '#CCCCCC', dash: 'solid' },
    },
  } as any;

  const waterfallHeight = feature_impact_trace.measure.length * 35 + 2;

  const histogramTrace: Data[] = [
    {
      y: kde.histogram.y,
      legendgroup: 'Expected final price',
      name: 'Expected final price',
      marker: { color: 'rgba(110, 67, 239, 0.4)' },
      xaxis: 'x2',
      type: 'histogram',
      histnorm: 'probability density',
      nbinsy: kde.histogram.ybins.size,
    },
    {
      legendgroup: 'Expected final price',
      name: 'Expected final price',
      marker: { color: PRICE_COLOR },
      x: kde.dist_line.x,
      y: kde.dist_line.y,
      mode: 'lines',
      xaxis: 'x2',
      showlegend: false,
    },
  ];

  const plotHeight = 400;

  return (
    <>
      <div className={styles.downloadBtnWrapper}>
        <DownloadButton
          onClick={onDownloadSimulationResults}
          isLoading={isDownloadingSimulation}
          text="Download Simulation results"
        />
      </div>
      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Simulation Results</h3>
        </div>
        <div className={styles.resultsCompositeBlocks}>
          <div>
            <h2>{`${price_description} in ${pred_window} months`}</h2>
            <div className={styles.relativeChangeBlock}>
              <div>
                <p>{y_pred.toFixed(2)}</p>
                <span>{price_units}</span>
              </div>
              <RelativeChange change={target_pct_diff} />
            </div>
          </div>
          <div>
            <h2>{`Today's ${price_description}`}</h2>
            <p>{y_latest.toFixed(2)}</p>
            <span>{price_units}</span>
          </div>
          <div>
            <h2>90% confidence interval</h2>
            <p>{`${y_ci_low.toFixed(2)} - ${y_ci_high.toFixed(2)}`}</p>
            <span>{price_units}</span>
          </div>
        </div>
        <br />
        <div>
          <Plot
            config={{ displayModeBar: false, displaylogo: false }}
            useResizeHandler={true}
            style={{ height: plotHeight }}
            data={[
              {
                x: price_series[0].date,
                y: price_series[0].value,
                name: price_series[0].name,
                mode: 'lines',
                showlegend: true,
              },
              {
                x: [simulation_date], // assuming this is a variable containing the simulation date
                y: [y_pred],
                name: 'Predicted price',
                error_y: {
                  type: 'data',
                  symmetric: false,
                  array: [y_ci_high - y_pred],
                  arrayminus: [y_pred - y_ci_low],
                  thickness: 1.5,
                  width: 8,
                  color: '#7F7F7F',
                },
                marker: {
                  size: 11,
                  color: '#FFD92F',
                },
                mode: 'markers',
              },
              {
                x: [todays_date, simulation_date],
                y: [y_latest, y_pred],
                mode: 'lines',
                line: {
                  color: PRICE_COLOR,
                  dash: 'dash',
                },
                showlegend: false,
              },
              ...histogramTrace,
            ]}
            layout={{
              height: plotHeight,
              margin: {
                t: 0,
                l: 50,
                r: 20,
                b: 30,
              },
              paper_bgcolor: 'transparent',
              plot_bgcolor: 'transparent',
              font: {
                color: 'white',
              },
              legend: {
                orientation: 'h',
                y: 1.15,
                x: -0.03,
              },
              xaxis: {
                linecolor: 'rgba(255,255,255,.5)',
                gridcolor: 'rgba(255,255,255,.5)',
                domain: [0, 0.9],
                zeroline: false,
                title: {
                  text: '',
                },
              },
              xaxis2: {
                linecolor: 'rgba(255,255,255,.5)',
                gridcolor: 'rgba(255,255,255,.5)',
                domain: [0.9, 1],
                showgrid: false,
                zeroline: false,
                showticklabels: false,
              },
              yaxis: {
                linecolor: 'rgba(255,255,255,.5)',
                title: {
                  text: '',
                },
              },
              annotations: [
                {
                  x: todays_date,
                  y: y_latest,
                  xref: 'x',
                  yref: 'y',
                  text: `<b>Current Price ${y_latest.toFixed(1)}</b>`,
                  showarrow: true,
                  bgcolor: PRICE_COLOR,
                  borderpad: 4,
                  arrowhead: 7,
                  arrowcolor: 'white',
                  ax: 0,
                  ay: -50,
                  font: {
                    color: 'white', //getCSSVariable('--text-color')
                  },
                },
              ],
              shapes: [
                {
                  type: 'rect',
                  x0: todays_date,
                  x1: simulation_date,
                  y0: 0,
                  y1: 1,
                  line: {
                    width: 0,
                  },
                  fillcolor: PRICE_COLOR,
                  opacity: 0.16,
                  layer: 'below',
                  xref: 'x',
                  yref: 'paper',
                },
                {
                  type: 'line',
                  x0: kde.mean_line.x0,
                  y0: kde.mean_line.y0,
                  x1: kde.mean_line.x1,
                  y1: kde.mean_line.y1,
                  xref: 'x2',
                  line: {
                    color: PRICE_COLOR,
                  },
                },
              ],
            }}
          />
        </div>
      </ContainerBlock>

      <div className={styles.downloadBtnWrapper}>
        <DownloadButton
          onClick={onDownloadFeatureImpact}
          isLoading={isDownloadingSimulation}
          text="Download Feature impact"
        />
      </div>
      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Feature impact</h3>
        </div>
        <br />
        <Plot
          data={[waterFallTrace]}
          config={{ displayModeBar: false, displaylogo: false }}
          useResizeHandler={true}
          style={{ width: '100%' }}
          layout={{
            yaxis: {
              autorange: 'reversed',
              linecolor: 'rgba(255,255,255,.5)',
            },
            xaxis: {
              linecolor: 'rgba(255,255,255,.5)',
              gridcolor: 'rgba(255,255,255,.5)',
            },
            font: {
              color: 'white',
            },
            margin: {
              t: 0,
              r: 10,
              l: 300,
              b: 30,
            },
            paper_bgcolor: PAPER_BG_COLOR,
            plot_bgcolor: PLOT_BG_COLOR,
            height: waterfallHeight,
          }}
        />
      </ContainerBlock>

      <div className={styles.downloadBtnWrapper}>
        <DownloadButton
          onClick={onDownloadSimulatorReport}
          isLoading={isDownloadingSimulation}
          text="Download Simulation report"
        />
      </div>
      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Simulation Report</h3>
        </div>
        <br />
        <Table {...dfDisplayProps} getCellProps={getCellProps} />
      </ContainerBlock>
    </>
  );
}

export default SimulationResults;
