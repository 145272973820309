import classNames from 'classnames';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  SortingRule,
  TableInstance,
  useExpanded,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import ContentPlaceholder from '../ContentPlaceholder/ContentPlaceholder';
import HeaderGroup from './Header/TableHeader';
import Pagination from './Pagination/Pagination';
import TableRow from './Row/TableRow';
import styles from './Table.module.scss';

interface Filters {
  [key: string]: {
    [key: string]: boolean;
  };
}

interface Props {
  data: Array<{
    [name: string]: any;
  }>;
  initialSortBy?: Array<SortingRule<any>>;
  filters?: Filters;
  tableStyles?: string;
  rowClassName?: string;
  getRowStyles?: (row: Row<any>) => { [name: string]: string };
  getCellProps?: (cell: any) => Record<string, any>;
  columns: Column<any>[];
  /* the React component that will be rendered after expanding row */
  expandContent?: (row: Row<any>) => ReactNode;
  /* some extra component that will be rendered after table */
  extraContent?: (props: TableInstance) => ReactNode;
  pagination?: boolean;
  isDataFetched?: boolean;
  manualPageSize?: number;
}

const getRowId = (row: any) => row.id;

const defaultPropGetter = () => ({});
const Table = ({
  data,
  initialSortBy = [],
  isDataFetched = true,
  filters = {},
  manualPageSize = 20,
  columns,
  pagination = false,
  expandContent,
  extraContent,
  tableStyles,
  rowClassName,
  getRowStyles,
  getCellProps = defaultPropGetter,
}: Props) => {
  const props = useTable(
    {
      autoResetSortBy: false,
      autoResetExpanded: false,
      getRowId,
      columns,
      data,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    setFilter,
    toggleRowExpanded,
    setPageSize,
    state: { expanded, pageIndex, pageSize },
  } = props;

  useEffect(() => {
    setPageSize(manualPageSize);
  }, [manualPageSize, setPageSize]);

  const { t } = useTranslation();

  // collapse old expanded rows if some new row was expanded recently
  useEffect(() => {
    const ids: string[] = Object.keys(expanded);
    toggleRowExpanded(ids.slice(0, ids.length - 1), false);
  }, [expanded, toggleRowExpanded]);

  useEffect(() => {
    Object.entries(filters).forEach(([name, filters]) => {
      setFilter(name, keys(pickBy(filters)));
    });
  }, [data, filters, setFilter]);

  return (
    <div className={styles.wrapper}>
      {extraContent && extraContent(props)}

      <div
        {...getTableProps()}
        className={classNames(styles.table, tableStyles)}
      >
        {headerGroups.map((headerGroup: any) => (
          <div
            {...headerGroup.getHeaderGroupProps([
              { style: { background: 'rgba(0, 0, 0, 0)' } },
            ])}
            className={styles.header}
          >
            {headerGroup.headers.map((column: any) => (
              <HeaderGroup
                column={column}
                {...column.getHeaderProps([
                  {
                    style: {
                      width: 0,
                      flex: '1 0 auto',
                      color: '#fff',
                    },
                  },
                ])}
              />
            ))}
          </div>
        ))}

        <ContentPlaceholder
          isLoading={!isDataFetched}
          doesContentExist={pagination ? page.length > 0 : rows.length > 0}
          noContentTitle={t('Table.noRecords')}
          height={300}
        >
          <div
            {...getTableBodyProps([
              {
                style: {
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
            ])}
          >
            {(pagination ? page : rows).map((row, rowIndex) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  row={row}
                  rowIndex={rowIndex}
                  expandedRowBody={expandContent || undefined}
                  rowClassName={rowClassName}
                  getRowStyles={getRowStyles}
                  getCellProps={getCellProps}
                />
              );
            })}
          </div>
        </ContentPlaceholder>

        {pagination && (
          <Pagination
            currentPage={pageIndex}
            pageSize={pageSize}
            numberOfEntities={pageCount * pageSize}
            goToPage={gotoPage}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
