import { ReactNode } from 'react';

import styles from './Subtitle.module.scss';

type SubtitleProps = {
  children: ReactNode;
};

const Subtitle = ({ children }: SubtitleProps) => (
  <p className={styles.wrapper}>{children}</p>
);

export default Subtitle;
