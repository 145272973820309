import { NodeControllerProps } from 'components/SimulatorController/SimulatorController';
import { SimulationParameters } from 'pages/Simulator/SimulatorResults';
import api from 'shared/api';
import { Variable } from 'shared/types/variable';

export const simulateResults = async (
  target: Variable,
  simulationParameters: SimulationParameters,
) => {
  const slider_values: Record<string, number> = {};
  for (let key in simulationParameters) {
    if (simulationParameters[key].used) {
      slider_values[key] = simulationParameters[key].value;
    }
  }

  const response = await api.post(`run/simulator/${target.simulator}`, {
    slider_values: slider_values,
  });

  let data: any = {};
  if (response.data.df_display === undefined) {
    data = JSON.parse(response.data);
  } else {
    data = response.data;
  }

  return {
    ...data,
    df_display: JSON.parse(data.df_display),
  };
};

export const getSimulatorGraph = async (namespace: string) => {
  const { data } = await api.get(`simulator/html/${namespace}`);
  return data;
};

export const getSimulatorProps = async (namespace: string) => {
  const { data } = await api.get(`simulator/${namespace}`);
  const simulatorParameters = (data.sliders as NodeControllerProps[]).reduce(
    (acc: SimulationParameters, e) => {
      acc[e.node] = {
        value: e.latest_value,
        used: false,
        classification: 'sampled',
      };
      return acc;
    },
    {},
  );

  return {
    simulatorPropsData: data,
    simulatorParametersData: simulatorParameters,
  };
};

export const getFeatureExplorer = async (namespace: string) => {
  const { data } = await api.get(`simulator/feature_explorer/${namespace}`);

  return data;
};

export const getBacktestingResult = async (namespace: string) => {
  const { data } = await api.get(`simulator/backtesting_results/${namespace}`);

  return {
    ...data,
    df_backtest_metrics: JSON.parse(data['df_backtest_metrics']),
  };
};
