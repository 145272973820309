import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import FileSaver from 'file-saver';
import { Matcher } from 'react-day-picker';
import { ROUTES } from 'routes';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Subheader from 'components/Subheader/Subheader';
import Dropdown from 'components/Dropdown/Dropdown';
import { SingleDatePicker } from 'components/Dates/DatePickerInput/DatePickerInput';
import Subtitle from 'components/Subtitle/Subtitle';
import IconAction from 'components/IconAction/IconAction';
import ContainerBlock from 'components/ContainerBlock/ContainerBlock';
import DownloadButton from 'components/DownloadButton/DownloadButton';
import SigmaControls from 'components/SigmaControls/SigmaControls';
import Skeleton from 'components/Skeleton/Skeleton';
import WaterFall, {
  DEMAND,
  MARKET,
  SUPPLY,
} from 'components/Graphs/Waterfall/Waterfall';
import FeatureImportance from 'components/Graphs/FeatureImportance/FeatureImportance';
import RadioButton from 'components/RadioButton/RadioButton';
import { ForecastCurve } from './ForecastCurveV2';

import { apiv2 } from 'shared/api';
import { AppContext } from 'shared/AppContext';
import { useCommodityTarget } from 'shared/hooks/useCommodityTarget';
import Notifier from 'shared/services/Notifier/Notifier';

import styles from './Forecasting.module.scss';

import {
  downloadFeatureContribution,
  downloadFeatureImportance,
  downloadForecastCurve,
} from 'service/download.service';
import {
  ForecastResponse,
  getForecastData,
  getXAI,
} from 'service/forecasting.service';

type LoadingState = {
  shaps: boolean;
  forecast: boolean;
};

const initialLoadingState: LoadingState = {
  shaps: true,
  forecast: true,
};

type forecastDates = {
  min_date: Date;
  max_date: Date;
  missing_dates: Array<Date>;
  dates: Array<Date>;
};

const CommodityForecasting = () => {
  const { serverSentEventId } = useContext(AppContext);
  const { t } = useTranslation();
  const { target, targets, selectedTarget, setSelectedTarget } =
    useCommodityTarget();

  const namespace = target?.price_prediction;
  const [forecastProps, setForecastingProps] = useState<ForecastResponse>();
  const [marketsShaps, setMarketsShaps] = useState<any>();
  const [disabledMatcher, setDisablesMatcher] = useState<Array<Matcher>>();
  const [isDownloadingForecastCurve, setIsDownloadingForecastCurve] =
    useState<boolean>(false);
  const [isDownloadingFeatContribution, setIsDownloadingFeatContribution] =
    useState<boolean>(false);
  const [isDownloadingFeatImportance, setIsDownloadingFeatImportance] =
    useState<boolean>(false);
  const [date, setDate] = useState<Date>();
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [sigma, setSigma] = useState<number>(2);
  const [accContribVar, setAccContribVar] = useState<string>(DEMAND);
  const [loadingState, setLoadingState] =
    useState<LoadingState>(initialLoadingState);

  useEffect(() => {
    document.title = t('Forecasting.title');
    if (namespace) {
      setLoadingState(initialLoadingState);
      // TODO: include in reducer and trigger reset reducer when changing namespace
      setDate(undefined);
      setForecastingProps(undefined);
      setMarketsShaps(undefined);
      setSigma(2);

      apiv2
        .get(`forecasting/${namespace}/dates`)
        .then(({ data }) => {
          const spec: forecastDates = {
            min_date: parseISO(data.min_date),
            max_date: parseISO(data.max_date),
            missing_dates: data.missing_dates.map((x: string) => parseISO(x)),
            dates: data.dates.map((x: string) => parseISO(x)),
          };
          const disabledDays = [
            { after: spec.max_date },
            { before: spec.min_date },
            ...spec.missing_dates,
          ];

          setDisablesMatcher(disabledDays);
          setDate(spec.max_date);
        })
        .catch(err => {
          setLoadingState({
            forecast: false,
            shaps: false,
          });
          console.error(err);
          // Notifier.show('Error trying to get dates', { type: 'error' });
        })
        .finally();
    }
  }, [serverSentEventId, namespace, t]);

  // Depends on namespace
  // but can only be triggered when date changes to avoid conflicting state
  useEffect(() => {
    const fetchData = async (date: Date) => {
      setLoadingState({ forecast: true, shaps: true });
      try {
        const xaiData = await getXAI(namespace!, date);
        const forecastDataResponse = await getForecastData(
          namespace!,
          date,
          sigma,
        );

        setMarketsShaps(xaiData);
        setForecastingProps(forecastDataResponse);
      } catch (error) {
        console.error(error);
        // Notifier.show('Error trying to get forecast data.', { type: 'error' });
      } finally {
        setLoadingState({ forecast: false, shaps: false });
      }
    };

    if (date) {
      fetchData(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, sigma]);

  const handleDownloadForecastCurve = async () => {
    setIsDownloadingForecastCurve(true);

    try {
      const forecastCurveFile = await downloadForecastCurve(
        namespace!,
        date!,
        sigma,
      );
      FileSaver.saveAs(forecastCurveFile);
    } catch (error) {
      // alert('Error trying to download file');
      console.error('Error trying to download file', error);
      // Notifier.show('Error trying to download file', { type: 'error' });
    } finally {
      setIsDownloadingForecastCurve(false);
    }
  };

  const handleDownloadFeatContribution = async () => {
    setIsDownloadingFeatContribution(true);

    try {
      const featureContributionFile = await downloadFeatureContribution(
        namespace!,
        date!,
      );
      FileSaver.saveAs(featureContributionFile);
    } catch (error) {
      // alert('Error trying to download file');
      console.error('Error trying to download file', error);
      // Notifier.show('Error trying to download file', { type: 'error' });
    } finally {
      setIsDownloadingFeatContribution(false);
    }
  };

  const handleDownloadFeatImportance = async () => {
    setIsDownloadingFeatImportance(true);

    try {
      const featureImportanceFile = await downloadFeatureImportance(
        namespace!,
        date!,
      );
      FileSaver.saveAs(featureImportanceFile);
    } catch (error) {
      // alert('Error trying to download file');
      console.error('Error trying to download file', error);
      // Notifier.show('Error trying to download file', { type: 'error' });
    } finally {
      setIsDownloadingFeatImportance(false);
    }
  };

  const accumulatedContributionsBySelectedVariable =
    marketsShaps?.markets_plots.find((obj: any) => obj.name === accContribVar);
  const contributionsOptions = [DEMAND, MARKET, SUPPLY];

  return (
    <>
      <Subheader title={t('Forecasting.title')}>
        {date && (
          <div>
            <Subtitle>{t('Forecasting.selectDate')}</Subtitle>
            <SingleDatePicker
              date={date}
              selectDate={date => {
                setLoadingState(initialLoadingState);
                setDate(date);
              }}
              disabledDays={disabledMatcher}
              isDatePickerVisible={isDatePickerVisible}
              setDatePickerVisible={setDatePickerVisible}
            />
          </div>
        )}
        <div className={styles.dropdownContainer}>
          <Dropdown
            options={targets}
            select={setSelectedTarget}
            selected={selectedTarget}
          />
        </div>
      </Subheader>
      <IconAction
        linkTo={ROUTES.backtesting}
        icon={faArrowRight}
        text={t('Forecasting.goToBacktesting')}
      />
      <div className={styles.downloadBtnWrapper}>
        <DownloadButton
          onClick={handleDownloadForecastCurve}
          isLoading={isDownloadingForecastCurve}
          text="Download Price Prediction Curve"
        />
      </div>

      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Forecast curve in 6 months horizon</h3>
          <SigmaControls
            currentSigma={sigma}
            onChangeSigma={sigma => setSigma(sigma)}
          />
        </div>
        {Object.values(loadingState).some(Boolean) || !forecastProps ? (
          <Skeleton height={450} />
        ) : (
          <ForecastCurve response={forecastProps} />
        )}
      </ContainerBlock>

      <div className={styles.downloadBtnWrapper}>
        <DownloadButton
          onClick={handleDownloadFeatContribution}
          isLoading={isDownloadingFeatContribution}
          text="Download Feature contribution"
        />
      </div>
      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Impact on model output</h3>
        </div>
        {Object.values(loadingState).some(Boolean) || !forecastProps ? (
          <Skeleton height={400} />
        ) : (
          <WaterFall
            waterfall={marketsShaps.market_summary}
            layout={{
              width: window.innerWidth - 310,
              height: 500,
            }}
            onClick={clickData => {
              console.debug(clickData.points[0].y);
            }}
          />
        )}
      </ContainerBlock>

      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Accumulated Contributions by variable</h3>
        </div>
        <p className={styles.containerBlockSubtitle}>
          To see contributions of individual features please select a group of
          variables.
        </p>
        <div className={styles.btnOptions}>
          {contributionsOptions.map((name: string) => (
            <RadioButton
              key={`option-${name}`}
              label={name}
              onChange={() => setAccContribVar(name)}
              checked={name === accContribVar}
            />
          ))}
        </div>
        {Object.values(loadingState).some(Boolean) ||
        !forecastProps ||
        !accumulatedContributionsBySelectedVariable ? (
          <Skeleton height={500} />
        ) : (
          <WaterFall
            waterfall={accumulatedContributionsBySelectedVariable}
            layout={{
              width: window.innerWidth - 310,
            }}
            groupSmallValues={0.5}
          />
        )}
      </ContainerBlock>

      <div className={styles.downloadBtnWrapper}>
        <DownloadButton
          onClick={handleDownloadFeatImportance}
          isLoading={isDownloadingFeatImportance}
          text="Download Feature importance"
        />
      </div>
      <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Top 12 features - Feature importance (%)</h3>
        </div>
        {Object.values(loadingState).some(Boolean) || !forecastProps ? (
          <Skeleton height={400} />
        ) : (
          <FeatureImportance data={marketsShaps.feature_importance} />
        )}
      </ContainerBlock>

      {/* <ContainerBlock>
        <div className={styles.containerBlockHeader}>
          <h3>Temporal Contributions for Price Prediction Model</h3>
        </div>
        {Object.values(loadingState).some(Boolean) ||
        !forecastProps ||
        !accumulatedContributionsBySelectedVariable ? (
          <Skeleton height={500} />
        ) : (
          <TemporalContributions
            monthlyShapsTrace={marketsShaps.monthly_shaps.trace}
          />
        )}
      </ContainerBlock> */}
    </>
  );
};

export default CommodityForecasting;
