import { ReactNode } from 'react';

import styles from './ContainerBlock.module.scss';

type ContainerBlockProps = {
  children: ReactNode;
};

const ContainerBlock = ({ children }: ContainerBlockProps) => (
  <div className={styles.wrapper}>{children}</div>
);

export default ContainerBlock;
