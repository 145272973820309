import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  DayPicker,
  CaptionProps,
  useNavigation,
  ModifiersClassNames,
  Matcher,
} from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import styles from './DatePickerInput.module.scss';

import { ReactComponent as ShortArrow } from 'shared/assets/images/shortArrow.svg';
import { ReactComponent as CalendarIcon } from 'shared/assets/images/icon-calendar.svg';
import { getFormattedDateWithWeekday } from 'shared/utils/dateHelpers';

const DatePickerNavBar = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <div className={styles.navBar}>
      <div className={classNames(styles.navButton, styles.navButtonPrev)}>
        <ShortArrow
          className={styles.arrowIcon}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        />
      </div>
      <div>{format(props.displayMonth, 'MMMM yyy')}</div>
      <div className={classNames(styles.navButton, styles.navButtonNext)}>
        <ShortArrow
          className={styles.arrowIcon}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        />
      </div>
    </div>
  );
};

const datePickerModifiersClassNames: ModifiersClassNames = {
  container: '',
  wrapper: styles.datePickerWrapper,
  interactionDisabled: '',
  navBar: styles.navBar,
  navButtonPrev: styles.navButtonPrev,
  navButtonNext: styles.navButtonNext,
  navButtonInteractionDisabled: styles.navButtonInteractionDisabled,
  months: styles.months,
  month: styles.month,
  caption: styles.caption,
  weekdays: styles.weekdays,
  weekdaysRow: styles.weekdaysRow,
  weekday: styles.weekday,
  weekNumber: '',
  body: '',
  week: styles.week,
  footer: '',
  disabled: styles.disabled,
  outside: '',
  table: styles.table,
  today: styles.dayToday,
  selected: styles.selected,
  range_middle: styles.rangeMiddleSelection,
};

interface RangeDateProps {
  text: string;
  selectDate: (date: Date) => void;
  dateToDisplay: Date | undefined;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  disabledDays: Matcher | Matcher[] | undefined;
  datePickerClassName?: string;
  inputClassName?: string;
  isDatePickerVisible: boolean;
  setDatePickerVisible: (isVisible: boolean) => void;
  inputIconColor?: string;
}

const DatePickerInput = ({
  text,
  selectDate,
  dateToDisplay,
  dateFrom,
  dateTo,
  disabledDays,
  datePickerClassName,
  inputClassName,
  isDatePickerVisible,
  setDatePickerVisible,
  inputIconColor,
}: RangeDateProps) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setDatePickerVisible(true)}
      onMouseLeave={() => setDatePickerVisible(false)}
    >
      <div
        className={classNames(
          styles.input,
          isDatePickerVisible && styles.inputHighlighted,
          inputClassName,
        )}
      >
        <span className={styles.inputText}>{text}</span>

        {dateToDisplay && (
          <span>
            {getFormattedDateWithWeekday(dateToDisplay, i18n.language)}
          </span>
        )}

        <div className={styles.inputIcon}>
          <ShortArrow
            className={styles.arrowIcon}
            style={{ color: inputIconColor || 'var(--icon-color)' }}
          />
        </div>
      </div>

      <div
        className={classNames(
          isDatePickerVisible && styles.datePickerVisible,
          styles.datePicker,
          datePickerClassName,
        )}
      >
        <DayPicker
          selected={{
            from: dateFrom,
            to: dateTo,
          }}
          disabled={disabledDays}
          classNames={styles}
          components={{
            Caption: DatePickerNavBar,
          }}
          defaultMonth={dateToDisplay ?? new Date()}
          className={styles.picker}
          formatters={{
            formatWeekdayName: (date: Date) => format(date, 'iiiii'),
          }}
          modifiersClassNames={datePickerModifiersClassNames}
          onDayClick={(day: Date) => selectDate(day)}
          mode="range"
        />
      </div>
    </div>
  );
};

interface SingleDateProps {
  selectDate: (date: Date) => void;
  date: Date | undefined;
  disabledDays: Matcher | Matcher[] | undefined;
  datePickerClassName?: string;
  inputClassName?: string;
  isDatePickerVisible: boolean;
  setDatePickerVisible: (isVisible: boolean) => void;
  inputIconColor?: string;
}

export const SingleDatePicker = ({
  selectDate,
  date,
  disabledDays,
  datePickerClassName,
  inputClassName,
  isDatePickerVisible,
  setDatePickerVisible,
  inputIconColor,
}: SingleDateProps) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setDatePickerVisible(true)}
      onMouseLeave={() => setDatePickerVisible(false)}
    >
      <div
        className={classNames(
          styles.input,
          isDatePickerVisible && styles.inputHighlighted,
          inputClassName,
        )}
      >
        {date && (
          <span className={styles.dateToDisplay}>
            {getFormattedDateWithWeekday(date, i18n.language)}
          </span>
        )}

        <div className={styles.inputIcon}>
          <CalendarIcon
            className={styles.calendarIcon}
            style={{ color: inputIconColor || 'var(--icon-color)' }}
          />
        </div>
      </div>

      <div
        className={classNames(
          isDatePickerVisible && styles.datePickerVisible,
          styles.datePicker,
          datePickerClassName,
        )}
      >
        <DayPicker
          selected={date}
          disabled={disabledDays}
          classNames={styles}
          components={{
            Caption: DatePickerNavBar,
          }}
          defaultMonth={date}
          className={styles.picker}
          formatters={{
            formatWeekdayName: (date: Date) => format(date, 'iiiii'),
          }}
          modifiersClassNames={datePickerModifiersClassNames}
          onDayClick={(day: Date) => selectDate(day)}
          mode="single"
          showOutsideDays
          fixedWeeks
        />
      </div>
    </div>
  );
};

export default DatePickerInput;
