import { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { ROUTES } from 'routes';
import appConfig from '../../application.json';
import styles from './AppRoutes.module.scss';

import SideBar from 'components/SideBar/SideBar';

import Backtesting from 'pages/Backtesting/Backtesting';
import Forecasting from 'pages/Forecasting/Forecasting';
import Landing from 'pages/Landing/Landing';
import OpenApiDocumentation from 'pages/OpenApiDocumentation/OpenApiDocumentation';
import Simulator from 'pages/Simulator/Simulator';

import { getApiUrl } from 'shared/api';
import { generateRandomId } from 'shared/utils/generateRandomId';
import { AppContext } from 'shared/AppContext';

export const AppRoutes = () => {
  const { setServerSentEventId } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      const eventSource = new EventSourcePolyfill(
        `${getApiUrl()}/server-events`,
      );

      eventSource.addEventListener('server_event', () =>
        setServerSentEventId(generateRandomId()),
      );

      eventSource.onopen = () =>
        console.log('SSE connected, waiting for server pushes');

      return () => {
        eventSource.close();
      };
    })();
  }, [setServerSentEventId]);

  return (
    <section className={styles.appContent}>
      <SideBar />

      <main>
        <Routes>
          <Route path={'/*'} element={<Landing />} />

          {appConfig?.pages?.forecasting && (
            <Route path={ROUTES.forecasting} element={<Forecasting />} />
          )}

          {appConfig?.pages?.backtesting && (
            <Route path={ROUTES.backtesting} element={<Backtesting />} />
          )}

          {appConfig?.pages?.simulator && (
            <Route path={ROUTES.simulator} element={<Simulator />} />
          )}

          {appConfig?.pages?.open_api_docs && (
            <Route
              path={ROUTES.openApiDocumentation}
              element={<OpenApiDocumentation />}
            />
          )}
        </Routes>
      </main>
    </section>
  );
};
