import { AxiosRequestConfig } from 'axios';

async function tokenInterceptor(config: AxiosRequestConfig) {
  const token = localStorage.getItem('token');

  const newConfig = { ...config, headers: config.headers || {} };

  newConfig.headers.Authorization = `Bearer ${token}`;

  return newConfig;
}

export default tokenInterceptor;
