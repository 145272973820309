import { Dispatch, SetStateAction } from 'react';

import { SimulationParameters } from './SimulatorResults';

import styles from './Simulator.module.scss';
import {
  NodeControllerProps,
  SimulatorController,
} from 'components/SimulatorController/SimulatorController';

type Sliders = NodeControllerProps[];

export interface SimulatorProps {
  sliders: Sliders;
  nodes: string[];
  target: string;
  child_nodes: Record<string, string[]>;
  parent_nodes: Record<string, string[]>;
}

function classifyNodesForSimulation(
  { nodes, target, child_nodes, parent_nodes }: SimulatorProps,
  simulationParameters: SimulationParameters,
) {
  const simulated_nodes = Object.keys(simulationParameters).filter(
    key => simulationParameters[key].used,
  );

  const parent_nodes_classification: Record<string, string> = {};
  const target_parent_nodes = parent_nodes[target];

  // target's parent nodes are either 'simulated' or 'sampled'

  for (let node of target_parent_nodes) {
    if (simulated_nodes.includes(node)) {
      parent_nodes_classification[node] = 'simulated';
    } else {
      parent_nodes_classification[node] = 'sampled';
    }
  }

  const nodes_classification = { ...parent_nodes_classification };
  // Features' parent nodes can be 'simulated', 'sampled', or 'unused'

  const inverse_nodes_topological = nodes
    .slice()
    .reverse()
    .filter(s => s !== target)
    .filter(s => !target_parent_nodes.includes(s));

  // Iterate in inverse topological order to ensure correct results.
  for (let node of inverse_nodes_topological) {
    // If there is at least 1 sampled child node, the parent node has to be either 'sampled' or 'simulated'

    const sampled_nodes = Object.keys(nodes_classification).filter(
      key => nodes_classification[key] === 'sampled',
    );
    const intersection = child_nodes[node].filter(node =>
      sampled_nodes.includes(node),
    );

    if (intersection.length > 0) {
      if (simulated_nodes.includes(node)) {
        nodes_classification[node] = 'simulated';
      } else {
        nodes_classification[node] = 'sampled';
      }
    } else {
      // If there are no 'sampled' child nodes (only 'simulated' and 'unused' child nodes),
      // the parent has to be 'unused'
      nodes_classification[node] = 'unused';
    }
  }

  const updatedSimulatorParams: SimulationParameters = {};
  for (let key in simulationParameters) {
    updatedSimulatorParams[key] = {
      value: simulationParameters[key].value,
      used: nodes_classification[key] === 'simulated',
      classification: nodes_classification[key],
    };
  }

  return updatedSimulatorParams;
}

export interface SimulatorFormProps {
  simulatorProps: SimulatorProps;
  simulationParameters: SimulationParameters;
  onSimulationParametersChange: Dispatch<SetStateAction<SimulationParameters>>;
}

function SimulatorForm({
  simulatorProps,
  simulationParameters,
  onSimulationParametersChange,
}: SimulatorFormProps) {
  const handleNodeChange = (node: string, value: number) => {
    // Props include original values
    const nodeParameters = simulationParameters[node];

    onSimulationParametersChange({
      ...simulationParameters,
      [node]: { ...nodeParameters, value },
    });
  };

  const setIsChecked = (node: string) => {
    onSimulationParametersChange(prev => {
      const nodeParameters = simulationParameters[node];
      const nextParameters = nodeParameters.used
        ? { used: false, classification: 'sampled' }
        : { used: true, classification: 'simulated' };

      const _simulationParameters = {
        ...prev,
        [node]: { ...nodeParameters, ...nextParameters },
      };

      return classifyNodesForSimulation(simulatorProps, _simulationParameters);
    });
  };

  let descriptionMap: Record<string, string> = {};
  for (let slider of simulatorProps.sliders) {
    descriptionMap[slider.node] = slider.description;
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
      className={styles.content}
    >
      {simulationParameters && simulatorProps.sliders && (
        <div className={styles.slidersContainer}>
          {simulatorProps.sliders.map(
            (e: NodeControllerProps, index: number) => (
              <SimulatorController
                key={index}
                {...e}
                defaultValue={e.latest_value}
                simulationParameters={simulationParameters}
                handleNodeChange={handleNodeChange}
                setIsChecked={setIsChecked}
              />
            ),
          )}
        </div>
      )}
    </form>
  );
}

export default SimulatorForm;
