declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_COMMODITY_ANALYTICS_API: string;
  REACT_APP_MID_BASE_URL: string;
  REACT_APP_MID_CLIENT_ID: string;
};
export const env: EnvType = { ...process.env, ...window.env };
