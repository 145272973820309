import { apiv2 } from 'shared/api';
import { format } from 'date-fns';

interface ReferencePoint {
  value: number;
  date: string;
}

export interface ForecastResponse {
  title: string;
  caption: string;
  ylabel: string;
  data: any;
  max_series: number;
  min_series: number;
  max_residual: number;
  reference_area: {
    min_price: ReferencePoint;
    max_price: ReferencePoint;
    forecast_value: ReferencePoint;
    current_price: ReferencePoint;
  };
  target_col: string;
  kde: any;
  horizon_months: number;
}

export const getXAI = async (namespace: string, date: Date) => {
  const { headers, data } = await apiv2.post(`forecasting/${namespace}/xai`, {
    prediction_made_on: format(date, 'yyyy-MM-dd'),
  });

  if (
    headers['content-type'] === 'application/json' &&
    typeof data !== 'object'
  ) {
    console.info('Expected JSON, but parsing failed.');
    return JSON.parse(data);
  } else {
    return data;
  }
};

export const getForecastData = async (
  namespace: string,
  date: Date,
  sigma: number,
): Promise<ForecastResponse> => {
  const { data } = await apiv2.post(`forecasting/${namespace}`, {
    prediction_made_on: format(date, 'yyyy-MM-dd'),
    sigma: sigma,
  });

  return {
    ...data,
    data: JSON.parse(data.data),
  };
};
