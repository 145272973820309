import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ShortArrow } from 'shared/assets/images/shortArrow.svg';

import styles from './Dropdown.module.scss';
import Subtitle from 'components/Subtitle/Subtitle';

export interface DropdownOption {
  id: string;
  name: string;
}

interface Props {
  label?: string;
  options: DropdownOption[];
  selected?: string;
  select: Dispatch<SetStateAction<string>>;
}

const Dropdown = ({ label, options, selected, select }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const name = options.find(option => option.id === selected)?.name;
  return (
    <div>
      <Subtitle>{t('Inputs.chooseCommodity')}</Subtitle>
      <div
        className={styles.container}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div
          className={classNames(styles.dropdown, isOpen && styles.highlighted)}
        >
          <div className={styles.label}>{label}</div>

          {selected && <div className={styles.selectedValue}>{name}</div>}

          <ShortArrow
            className={classNames(
              styles.expandIcon,
              isOpen && styles.collapseIcon,
            )}
          />
        </div>

        {isOpen && (
          <ul className={styles.options} tabIndex={-1}>
            {options.map((option, index) => (
              <li
                onClick={() => {
                  select(option.id);
                  setOpen(false);
                }}
                className={styles.option}
                key={`${option}${index}`}
                tabIndex={0}
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
