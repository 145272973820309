import { useNavigate, useLocation } from 'react-router-dom';
import './Loading.css';

import Spinner from './Spinner';

import { useAuth } from 'oidc-react';
import { useEffect } from 'react';

import classNames from 'classnames';
import styles from './Login.module.scss';

interface Props {
  children: JSX.Element;
}

const LoginWrapper = ({ children }: Props) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Navigate is neccesary to clean url params and avoid downstream bugs with reloading page
    // Must happen as children of Router component
    if (auth && auth.userData && location.pathname === '/auth/callback') {
      navigate('/');
      return;
    }
  }, [location, auth, navigate]);

  if (auth && auth.userData) {
    localStorage.setItem('token', auth.userData.access_token);
    return children;
  }

  return (
    <div className="LoadingPage">
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h2>Please login</h2>
        <button
          //id="login"
          onClick={e => {
            e.preventDefault();
            auth.signIn();
          }}
          className={classNames([styles.primary])}
          style={{ marginRight: 0 }}
        >
          {auth.isLoading ? <Spinner /> : 'Login'}
        </button>
      </div>
    </div>
  );
};

export default LoginWrapper;
