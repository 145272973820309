import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import styles from './NavBarLink.module.scss';

interface Props {
  path: ROUTES;
  icon: ReactNode;
  label: string;
  style?: any;
}

interface BtnProps {
  onClick: () => void;
  icon: ReactNode;
  label: string;
  style?: any;
}

export const NavBarLink = ({ path, icon, label, style }: Props) => (
  <li style={{ ...style }}>
    <NavLink
      to={path}
      className={({ isActive }) =>
        classNames(styles.container, isActive && styles.activeLink)
      }
    >
      <div className={styles.content}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{label}</div>
      </div>
    </NavLink>
  </li>
);

export const NavBarButton = ({ onClick, icon, label, style }: BtnProps) => (
  <li style={{ ...style }}>
    <button onClick={onClick} className={styles.btn}>
      <div className={styles.content}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{label}</div>
      </div>
    </button>
  </li>
);
