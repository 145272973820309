import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';
import { useAuth } from 'oidc-react';
import appConfig from 'application.json';

import NavBar from '../NavBar/NavBar';
import styles from './SideBar.module.scss';
import Settings from '../Settings/Settings';

import { ReactComponent as Logo } from 'shared/assets/images/pages_icons/project.svg';
import { ReactComponent as Name } from 'shared/assets/images/pages_icons/name.svg';
import { ReactComponent as ApiIcon } from 'shared/assets/images/pages_icons/new-api-icon.svg';
import { ReactComponent as LogoutIcon } from 'shared/assets/images/pages_icons/new-logout-icon.svg';

import {
  NavBarLink,
  NavBarButton,
} from 'components/NavBar/NavBarLink/NavBarLink';

const ProjectLogo = () => (
  <NavLink to={'/home'}>
    <div className={styles.logoWrapper}>
      <Logo width={42} style={{ fill: 'white' }} />
      <Name width={98} style={{ fill: 'white' }} />
    </div>
  </NavLink>
);

const SideBar = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const handleLogOut = () => {
    auth.signOut();
    localStorage.removeItem('token');
  };

  return (
    <aside className={styles.container}>
      <div className={styles.logo}>
        <ProjectLogo />
      </div>
      <NavBar />
      <ul className={styles.buttonsContainer}>
        {appConfig?.pages?.open_api_docs && (
          <NavBarLink
            path={ROUTES.openApiDocumentation}
            icon={<ApiIcon />}
            label={t('Routes.docs')}
          />
        )}
        <Settings />
        <NavBarButton
          onClick={handleLogOut}
          icon={<LogoutIcon />}
          label="Logout"
        />
      </ul>
    </aside>
  );
};

export default SideBar;
