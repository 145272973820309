import ReactDOM from 'react-dom';

import { env } from './env';
import App from './App';

import './shared/i18n/i18n';
import 'leaflet/dist/leaflet.css';
import './shared/styles/index.scss';
import * as serviceWorker from './shared/serviceWorker';

export const oidcConfig = {
  onSignIn: async (user: any) => {
    window.location.hash = '';
  },
  authority: 'https://auth.int.mckinsey.id/auth/realms/r',
  clientId: env.REACT_APP_MID_CLIENT_ID,
  redirectUri: `${env.REACT_APP_MID_BASE_URL}/auth/callback`,
};
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CAI-PWA
serviceWorker.unregister();
