import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import LoginWrapper from './pages/MIDAuth/LoginWrapper';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { AppRoutes } from 'components/AppRoutes/AppRoutes';
import { AppContextProvider } from 'shared/AppContext';
import { ThemeProvider } from 'shared/themes/ThemeContext';
import { env } from './env';

import { AuthProvider } from 'oidc-react';
import { oidcConfig } from 'index';

const App = () => {
  const [title] = useState('Default Title');
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
    if (
      env.REACT_APP_COMMODITY_ANALYTICS_API !== undefined &&
      env.REACT_APP_MID_CLIENT_ID !== undefined &&
      env.REACT_APP_COMMODITY_ANALYTICS_API !== '' &&
      env.REACT_APP_MID_CLIENT_ID !== '' &&
      env.REACT_APP_COMMODITY_ANALYTICS_API !== null &&
      env.REACT_APP_MID_CLIENT_ID !== null
    )
      console.log('Loaded env vars');
  }, [title]);

  return (
    <ThemeProvider>
      <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          <ErrorBoundary>
            <LoginWrapper>
              <AppContextProvider>
                <AppRoutes />
              </AppContextProvider>
            </LoginWrapper>
          </ErrorBoundary>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
