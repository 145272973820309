import styles from './Skeleton.module.scss';

type SkeletonProps = {
  height: number;
};

const Skeleton = ({ height }: SkeletonProps) => (
  <div style={{ height: `${height}px` }} className={styles.wrapper}>
    &nbsp;
  </div>
);

export default Skeleton;
