import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { add, format } from 'date-fns';
import { Data, Shape } from 'plotly.js';
import Plot from 'react-plotly.js';

import ThemeContext from 'shared/themes/ThemeContext';
import {
  mapDataFrametoTableProps,
  axisfont,
  DECREASE_COLOR,
  INCREASE_COLOR,
  PRICE_COLOR,
  PAPER_BG_COLOR,
  PLOT_BG_COLOR,
} from 'shared/utils/plotly';
import { useCommodityTarget } from 'shared/hooks/useCommodityTarget';

import styles from './Forecasting.module.scss';
import CustomLegend from '../../components/CustomLegend/CustomLegend';
import { ForecastResponse } from 'service/forecasting.service';

export interface ForecastProps {
  response: ForecastResponse;
}

interface PlotState {
  data: Plotly.Data[];
  config: Partial<Plotly.Config>;
  layout?: Partial<Plotly.Layout>;
}

interface ForecastPlotProps {
  plotState: PlotState;
  setPlotState: Dispatch<SetStateAction<PlotState>>;
}

function formatLegendString(inputString: string) {
  const formattedString = inputString.replace(/^prm__refinitiv_dss__/, '');
  const finalString = formattedString.replaceAll('_', ' ');

  return `PRM refinitiv dss ${finalString}`;
}

function ForecastPlot({ plotState, setPlotState }: ForecastPlotProps) {
  const { layout, data, config } = plotState;
  if (layout) {
    return (
      <div className={styles.forecastPlotWrapper}>
        <Plot
          useResizeHandler={true}
          style={{ width: '100%', height: '100%' }}
          config={config}
          data={data}
          layout={layout}
          onUpdate={figure => {
            setPlotState(state => ({ ...state, ...figure }));
          }}
        />
      </div>
    );
  } else {
    return null;
  }
}

export function ForecastCurve({ response }: ForecastProps) {
  const { target } = useCommodityTarget();
  const { getCSSVariable } = useContext(ThemeContext);

  const [plotState, setPlotState] = useState<PlotState>({
    config: { displayModeBar: false, displaylogo: false },
    data: [],
    layout: undefined,
  });

  const marginLeft = 50;
  const marginTop = 40;

  useEffect(() => {
    const { target_col, data: rawData, kde, horizon_months } = response;

    const dataframe: any = mapDataFrametoTableProps(rawData);
    const dates: string[] = dataframe.data.map((row: any) => row.date);

    const actualValues: number[] = dataframe.data.map(
      (row: any) => row[target_col],
    );
    const predicted: number[] = dataframe.data.map(
      (row: any) => row.predicted_price,
    );

    const low_int: number[] = dataframe.data.map((row: any) => row.low_int);
    const up_int: number[] = dataframe.data.map((row: any) => row.up_int);

    const color = getCSSVariable('--selection-color');
    const histogramTrace: Data[] = [
      {
        y: kde.histogram.y,
        legendgroup: `Forecast distribution`,
        name: `Distribution of ${horizon_months}M point prediction.`,
        marker: { color: color, opacity: 0.6 },
        xaxis: 'x2',
        type: 'histogram',
        histnorm: 'probability density',
        nbinsy: kde.histogram.ybins.size,
      },
      {
        legendgroup: `Forecast distribution`,
        marker: { color: color },
        x: kde.dist_line.x,
        y: kde.dist_line.y,
        mode: 'lines',
        xaxis: 'x2',
        showlegend: false,
      },
    ];

    const data = [
      {
        x: dates,
        y: actualValues,
        mode: 'lines',
        name: target_col,
        line: { color: '#00a9f4' },
      },
      {
        x: dates,
        y: low_int,
        mode: 'lines',
        name: 'lower confidence interval',
        line: { width: 0 },
        showlegend: false,
      },
      {
        x: dates,
        y: predicted,
        mode: 'lines',
        name: 'Predicted price.',
        line: { color: PRICE_COLOR },
        fill: 'tonexty',
        fillcolor: 'rgba(110,67,239, 0.4)',
      },
      {
        x: dates,
        y: up_int,
        mode: 'lines',
        name: 'upper confidence interval',
        line: { width: 0 },
        fill: 'tonexty',
        fillcolor: 'rgba(110,67,239, 0.4)',
        showlegend: false,
      },
      ...histogramTrace,
    ];
    if (target) {
      setPlotState(state => ({ ...state, data }));
    }
  }, [getCSSVariable, response, target]);

  // Layout
  useEffect(() => {
    if (target) {
      const {
        horizon_months,
        reference_area,
        kde,
        min_series,
        max_series,
        max_residual,
      } = response;

      const { mean_line } = kde;
      const color = getCSSVariable('--selection-color');
      const lastDate = reference_area.forecast_value.date;

      const forecastIncrease =
        reference_area.forecast_value.value >
        reference_area.current_price.value;
      const annotations: any[] = [
        {
          x: reference_area.min_price.date,
          y: reference_area.min_price.value,
          xref: 'x',
          yref: 'y',
          text: `<b>Min: ${reference_area.min_price.value.toFixed(1)}</b>`,
          showarrow: true,
          arrowhead: 7,
          arrowcolor: 'white',
          bgcolor: DECREASE_COLOR,
          borderpad: 4,
          ax: 0,
          ay: 70,
          font: {
            color: 'white',
          },
        },
        {
          x: reference_area.max_price.date,
          y: reference_area.max_price.value,
          xref: 'x',
          yref: 'y',
          text: `<b>Max: ${reference_area.max_price.value.toFixed(1)}</b>`,
          showarrow: true,
          arrowhead: 7,
          arrowcolor: 'white',
          bgcolor: INCREASE_COLOR,
          borderpad: 4,
          ax: 0,
          ay: -40,
          font: {
            color: 'white',
          },
        },
        {
          x: reference_area.forecast_value.date,
          y: reference_area.forecast_value.value,
          xref: 'x',
          yref: 'y',
          text: `<b>${horizon_months}M Forecast: ${reference_area.forecast_value.value.toFixed(
            1,
          )}</b>`,
          showarrow: true,
          arrowhead: 7,
          arrowcolor: 'white',
          bgcolor: forecastIncrease ? INCREASE_COLOR : DECREASE_COLOR,
          borderpad: 4,
          ax: 0,
          ay: -40,
          font: {
            color: 'white',
          },
        },
        {
          x: reference_area.current_price.date,
          y: reference_area.current_price.value,
          xref: 'x',
          yref: 'y',
          text: `<b>Current Price ${reference_area.current_price.value.toFixed(
            1,
          )}</b>`,
          showarrow: true,
          arrowhead: 7,
          arrowcolor: 'white',
          bgcolor: PRICE_COLOR,
          borderpad: 4,
          ax: 0,
          ay: 70,
          font: {
            color: 'white',
          },
        },
      ];

      const shapes: Partial<Shape>[] = [
        {
          name: `Distribution of ${horizon_months}M point prediction.`,
          type: 'line',
          x0: mean_line.x0,
          y0: mean_line.y0,
          x1: mean_line.x1,
          y1: mean_line.y1,
          xref: 'x2',
          line: {
            color: color,
          },
        },
      ];
      const padding_months = 8;

      const paddedDate = format(
        add(new Date(lastDate), { months: padding_months }),
        'yyyy-MM-dd',
      );
      const startingDate = format(
        add(new Date(lastDate), { months: -(12 * 5 + 6 + 1) }),
        'yyyy-MM-dd',
      );
      const layout: Partial<Plotly.Layout> = {
        xaxis: {
          domain: [0, 1],
          showgrid: true,
          gridcolor: 'rgb(49,51,63)',
          title: {
            text: 'Choose time',
            font: axisfont,
          },
          rangeselector: {
            bgcolor: 'transparent',
            activecolor: 'rgba(255,255,255,0.2)',
            bordercolor: '#fff',
            borderwidth: 1,
            buttons: [
              {
                count: 1 + 6 + 1 + padding_months,
                label: '1M',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 3 + 6 + 1 + padding_months,
                label: '3M',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 12 + 6 + 1 + padding_months, // 12 months + 6 predictions months + 1 padding month
                label: '1Y',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 5 * 12 + 6 + 1 + padding_months, // 12 months + 6 predictions months + 1 padding month
                label: '5Y',
                step: 'month',
                stepmode: 'backward',
              },
              {
                count: 98 + 6 + 1 + padding_months, // 12 months + 6 predictions months + 1 padding month
                label: 'All',
                step: 'month',
                stepmode: 'backward',
              },
            ],
          },
          rangeslider: { visible: true, range: [startingDate, paddedDate] },
          range: [startingDate, paddedDate],
          type: 'date',
        },
        yaxis: {
          range: [
            Math.max(min_series - max_residual - 10, 1),
            max_series + max_residual + 10,
          ],
          showgrid: true,
          gridcolor: 'rgb(49,51,63)',
        },
        xaxis2: {
          domain: [0.9, 1],
          showgrid: false,
          zeroline: false,
          showticklabels: false,
        },
        paper_bgcolor: PAPER_BG_COLOR,
        plot_bgcolor: PLOT_BG_COLOR,
        annotations: annotations,
        shapes: shapes,
        font: {
          color: 'white', //getCSSVariable('--text-color')
          family: 'MckinseySans',
        },
        margin: {
          l: marginLeft,
          t: marginTop,
          r: 0,
          b: 0,
        },
        showlegend: false,
      };

      setPlotState(state => {
        var mergedLayout = { ...layout };
        if (state?.layout?.xaxis) {
          mergedLayout['xaxis'] = state.layout.xaxis;
        }
        return {
          ...state,
          layout: mergedLayout,
        };
      });
    }
  }, [target, response, getCSSVariable]);

  const legends = [
    {
      text: plotState?.data[0]?.name
        ? formatLegendString(plotState?.data[0]?.name)
        : '',
      color: '#009df9',
    },
    {
      text: 'Predicted price',
      color: '#3949ab',
    },
    {
      text: 'Distribution of 6M point prediction',
      color: '#ffa800',
    },
  ];

  if (plotState) {
    return (
      <>
        <CustomLegend legends={legends} />
        <ForecastPlot plotState={plotState} setPlotState={setPlotState} />
      </>
    );
  } else {
    return <p>No plot state</p>;
  }
}
