import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Preloader from '../Preloader/Preloader';
import styles from './ContentPlaceholder.module.scss';

interface Props {
  isLoading?: boolean;
  doesContentExist?: boolean;
  noContentTitle?: string;
  height?: number | string;
  width?: number | string;
  children?: ReactNode;
  contentClassName?: string;
  loaderClassName?: string;
}

export function Loading() {
  return (
    <div
      style={{ width: '100%', height: '100%', marginTop: 50, marginBottom: 50 }}
      className={classNames(styles.loadingContainer)}
    >
      <Preloader />
    </div>
  );
}

const ContentPlaceholder = ({
  isLoading = false,
  doesContentExist = true,
  loaderClassName,
  noContentTitle,
  height = '100%',
  width = '100%',
  children,
  contentClassName,
}: Props) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!doesContentExist) {
    return (
      <div
        className={classNames(
          styles.noContentContainer,
          styles.noContentClassName,
        )}
        style={{ width, height }}
      >
        {noContentTitle}
      </div>
    );
  }

  if (!children) return null;

  return (
    <span
      className={classNames(
        styles.fadeInAnimation,
        styles.contentContainer,
        contentClassName,
      )}
    >
      {children}
    </span>
  );
};

export default ContentPlaceholder;
