import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';

import appConfig from 'application.json';

import { ReactComponent as SimulatorIcon } from 'shared/assets/images/pages_icons/new-simulator-icon.svg';
import { ReactComponent as ForecastIcon } from 'shared/assets/images/pages_icons/new-forecast-icon.svg';

import styles from './NavBar.module.scss';
import { NavBarLink } from './NavBarLink/NavBarLink';

interface SvgProps {
  Icon: React.ComponentType<any>;
}

function SvgIcon({ Icon }: SvgProps): ReactElement {
  return <Icon style={{ width: 30 }} />;
}

const NavBar = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <nav className={styles.navBar}>
        <ul className={styles.linkList}>
          {appConfig?.pages?.forecasting && (
            <NavBarLink
              path={ROUTES.forecasting}
              icon={<SvgIcon Icon={ForecastIcon} />}
              label={t('Routes.forecasting')}
            />
          )}

          {appConfig?.pages?.simulator && (
            <NavBarLink
              path={ROUTES.simulator}
              icon={<SvgIcon Icon={SimulatorIcon} />}
              label={t('Routes.simulator')}
            />
          )}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
