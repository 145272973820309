import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from './Button.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'contained' | 'text' | 'outlined' | 'none';
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button = ({
  type = 'contained',
  children,
  onClick,
  className,
  disabled = false,
  isLoading,
}: Props) => {
  return (
    <button
      className={classNames(styles.button, styles[type], className)}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading && (
        <FontAwesomeIcon
          icon={faSpinner}
          style={{ marginRight: '8px' }}
          className="fa-spin"
        />
      )}
      {children}
    </button>
  );
};

export default Button;
