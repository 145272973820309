import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import config from '../../application.json';
import darkTheme from './dark.module.scss';

const THEME_NAMES = {
  dark: 'dark',
};

const themes: { [name: string]: string } = {
  dark: darkTheme.darkTheme,
};

interface ContextProps {
  theme: string;
  updateTheme: Dispatch<SetStateAction<string>>;
  getCSSVariable: (variableName: string, node?: Element) => string;
}

const defaultState = {
  theme: config.default_theme,
  updateTheme: () => {},
  getCSSVariable: () => {
    return '';
  },
};

const ThemeContext = React.createContext<ContextProps>(defaultState);

const initialTheme = localStorage.getItem('theme') || defaultState.theme;

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, updateTheme] = useState(initialTheme);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const getCSSVariable = (variableName: string, node?: Element) => {
    const element = node ? node : ref?.current;

    if (element) {
      return getComputedStyle(element, null).getPropertyValue(variableName);
    }

    return '';
  };

  return (
    <ThemeContext.Provider value={{ theme, updateTheme, getCSSVariable }}>
      <ThemeContext.Consumer>
        {({ theme }) => (
          <div
            ref={ref}
            className={(theme && themes[theme]) || themes[defaultState.theme]}
            style={{ backgroundColor: 'var(--background-color)' }}
          >
            {children}
          </div>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
export { ThemeProvider, THEME_NAMES };
